@import '../../styles/variables.scss';

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 20%;
  justify-content: center;

    .text-title{
      color: var(--color-dark-600, #061237);
      text-align: center;
      font-size: 32px;
      padding: 30px 10px 1px;
    }

    .text-description{
      color: var(--color-dark-600, #061237);
      text-align: center;
      font-size: 1rem;
      padding: 30px 10px 1px;
    }

    .continue-button {
      width: 153px;
      justify-content: center;
      border-radius: 9999px;

      text-align: center;
      font-family: Avenir Next;
      font-weight: 700;
      letter-spacing: 0.2px;
      padding: 8px 20px;
    }
}
