@import '../../styles/variables.scss';

.md-container {
  >p {
    padding: 0;
    margin: 0;
  }
}

.results-page-container {
  font-family: Avenir;
  margin: 2rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .purple-text {
    background: $color-primary-1;
    color: white;
    border-radius: 50px;
    font-family: $font-family-avenir-bold;
    text-align: center;
    padding: 1rem 2rem;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
  }

  .results-header {
    @media screen and (max-width: 767px) {
      margin: 1rem 0 0;
      border-radius: 14px;
      height: 150px;
    }
  }

  .categories-container {

    width: 100%;

    .category-button {
      width: 100%;
      height: 150px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      border-radius: 14px;
      margin: auto;

      @media screen and (max-width: 767px) {
        padding: 1rem 0.5rem;
      }

      @media screen and (min-width: 768px) {
        padding: 1rem;
      }

      .category-name-container {
        font-family: $font-family-avenir-bold;
        background: white;
        color: $color-primary-1;
        border-radius: 25px;
        text-align: center;
        font-size: medium;
        padding: 0.25rem 0.7rem;
      }

      cursor: pointer;

      & svg {
        fill: white;
      }
    }
  }
}

.arrow-back-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: rgba($color: #000000, $alpha: 0.3);
  display: flex;

  position: absolute;
  top: 0;
  left: 0;
  transform: translate(1rem, 1rem);

  >svg {
    margin: auto;
  }
}

.sync-loader {
  > span > span {
    background-color: $color-primary-1 !important;
  }
}

.category-container {
  width: 100%;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    align-self: center;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: x-large;
    font-weight: bolder;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
  }

  @media screen and (max-width: 767px) {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 85%),
      var(--bg-image-src);
    background-repeat: no-repeat;
    background-size: 100% 250px;

    .header-container {
      background: none;

      .title {
        margin: 3rem auto 4rem;
      }
    }

    .collapse {
      transition: height 200ms ease;
    }

    .collapsing {
      transition: height 200ms ease;
    }

    .collapse .mt-4 {
      transition: opacity 200ms ease;
    }

    .collapse.show .mt-4 {
      opacity: 1;
    }

    .collapse:not(.show) .mt-4 {
      opacity: 0;
    }

    .collapse-container {
      margin: 1rem 0;

      @media screen and (min-width: $screen-breakpoint-md) {
        width: 70%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .header-container {
      position: relative;
      background: none;
      padding: 5%;

      .title {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.4) 85%),
          var(--bg-image-src);
        background-repeat: no-repeat;
        background-position: 0 35%;
        background-size: cover;
        height: 200px;
        width: 100%;
        border-radius: 25px;
        box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}

.change-parameters-container {
  background-color: $color-background-1;
  margin: 1rem 0;
  border-radius: 20px;

  & span>span {
    //spinner color
    background-color: $color-primary-1 !important;
  }

  @media screen and (max-width: 767px) {
    >.card {
      margin: 0.5rem;
      gap: 2rem;
      flex-direction: column;

      .change-params-buttons {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  @media screen and (min-width: $screen-breakpoint-md) {
    border-radius: 0;
    padding: 0;

    >.card {
      gap: 1rem;
      margin: 1rem 0;
      flex-direction: row;
      justify-content: space-between;

      .change-params-buttons {
        flex-direction: row;
        justify-content: flex-end;
        gap: 2rem;
      }
    }
  }

  >.card {
    display: flex;
    padding: 1rem;

    .change-params-buttons {
      display: flex;
    }
  }
}

.subcategories-cards-container {
  background-color: $color-background-1;
  border-radius: 17px;

  @media screen and (max-width: 767px) {
    padding: 1rem 0;

    .sbcat-card {
      margin: 0 0.5rem;

      .card-footer {
        margin: 0 auto;
        width: 95%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    border-radius: 0;
    margin: 1% 0;
    padding: 0;

    .sbcat-card {
      margin-bottom: 1rem;

      .card-body,
      .card-footer {
        margin: 0 auto 1rem;
        width: 90%;
      }
    }
  }

  .sbcat-card {
    padding: 0;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.3);

    .card-footer {
      border: none;
      background: none;
      display: flex;
      justify-content: center;
    }

    .card-header {
      color: black;
      font-weight: bolder;
      font-size: large;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      padding: 1.5rem;
      border-radius: inherit;
      border: none;
    }

    .buttons-container {
      margin-top: 1.5rem;
      display: flex;
      flex-wrap: wrap;

      justify-content: center;
      align-items: center;
    }

    &.card-container {
      @media screen and (max-width: 767px) {
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        width: 5%;
      }
    }

    .icon-button {
      margin: 0 1.2rem;
    }

    .sbcat-buttons-array {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      .btn-primary {
        background: #34B692 !important;
        color: white;
        border: 1px solid #34B692;
        box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.2);
        font-weight: bolder;
        font-size: medium !important;
        padding: 1rem !important;

        &.first {
          border-radius: 14px 0 0 14px;
        }

        &.second {
          border-radius: 0;
        }

        &.third {
          border-radius: 0 14px 14px 0;
        }

        &.active {
          background: white !important;
          color: #34B692;
        }
      }

      .btn-primary:hover {
        font-weight: bolder;
        background: white !important;
        color: #34B692;
      }
    }
  }
}

.percentile-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: calc($screen-breakpoint-md - 1px)) {
    width: 100%;
    margin: 1rem auto 2rem;
  }

  @media screen and (min-width: $screen-breakpoint-md) {
    margin: 2rem auto;
    width: 85%;
    padding: 1rem 0;
  }
}

.sbcat-tooltip {
  background: $color-primary-1;
  opacity: 1 !important;
  padding: 0 0.8rem;
  border-radius: 25px;
  font-size: small;
  box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.3);

  color: white;
}

.info-container {
  margin: 1rem 0;
  padding: 2rem 1rem 1rem;

  background: none;
  border-radius: 25px;
  border: 1px solid $color-primary-1;
  position: relative;

  display: flex;
  flex-direction: column;

  p {
    text-align: justify;
  }

  &::after {
    content: var(--info-container-header);
    white-space: nowrap;
    position: absolute;

    color: white;
    background: $color-primary-1;
    border-radius: $border-radius;

    font-family: Avenir;
    font-size: medium;
    font-weight: bold;

    display: flex;
    justify-content: center;

    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.4rem 2rem;

  }
}

.mynu-secondary-text {
  color: $color-secondary-1;
}