@import '../../styles/variables.scss';

.button-badge-container {
  cursor: pointer;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  position: relative;

  @media screen and (max-width: calc($screen-breakpoint-md - 1px)) {
    height: 50px;
    width: 50px;
  }

  @media screen and (min-width: $screen-breakpoint-md) {
    width: var(--button-container-width);
    height: var(--button-container-width);
  }

  .top-badge {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.005rem solid lightgray;

    background: $color-primary-1;
    border-radius: 50%;
    position: absolute;
    transform: translate(100%, -100%);

    >svg {
      stroke: white;
      height: 25px;
      width: 25px;
    }
  }

  .button-content {
    color: $color-primary-1;

    & svg {
      stroke: $color-primary-1;
      // fill: $color-primary-1;

      @media screen and (max-width: calc($screen-breakpoint-md - 1px)) {
        height: 35px;
        width: 35px;
      }

      @media screen and (min-width: $screen-breakpoint-md) {
        width: var(--svg-size);
        height: var(--svg-size);
      }
    }
  }

  &::after {
    content: var(--after-content);

    text-align: center;
    font-size: small;
    font-family: Avenir;
    font-weight: bold;
    line-break: loose;

    position: absolute;
    top: 110%;
    left: 50%;

    transform: translate(-50%, 0);

    min-width: 70px;

    padding: 0;
    margin: 0;
  }

  &.active {
    box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.5);

    .top-badge {
      background: white;

      & svg {
        stroke: $color-primary-1;
      }
    }
  }

  &:hover {
    filter: brightness(0.95);
  }

  &.active {
    box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.5);
  }
}