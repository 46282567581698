  @import '../../../../../styles/variables.scss';

  .diet-accordion-container {
    font-family: Avenir;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    & .accordion-header {
      border-radius: 25px !important;
      box-shadow: 0px 1px 1px 1px #DBDBDB;

      position: sticky;
      top: 60px;
      z-index: 1000;
  
      &.active {
        box-shadow: none;
      }

      .accordion-button{
        border-radius: inherit;
        box-shadow: inherit;
      }
    }

    .accordion-item {
      border: none;
    }
    

    .accordion-body {
      padding: 1%;
    }
  }

  .ingredient-text {
    text-align: left;
    padding: 2% 0;
    max-width: fit-content;
    text-wrap: wrap;
    text-overflow: ellipsis;

    .food-title {
      font-family: $font-family-avenir;
      font-weight: bolder;
      font-size: 14px;
    }

    .food-portion {
      font-size: 12px;
    }
  }

  .meal-card-container {
    width: 300px;
  }

  .meal-card-container,
  .fes-card-container {

    margin: 1%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 1% 0;
    }

    .meal-card {
      border-radius: 20px;
      background-color: #FEF5E3;
      width: 100%;
      margin: 0;
      border: none;

      .card-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
      }

      .meal-icon {
        width: 48px;
        height: 48px;
        background-image: url("../../../../../assets/icons/meal-icon.svg");
      }

      .meal-title {
        font-size: 1.125rem;
        color: #F7B300;
      }

      .change-meal-button {
        font-size: 0.875rem;
        text-decoration: none;
      }

      .reload-button {
        color: #F7B300;
      }

      ul {
        list-style: none;
        padding-left: 0px;
      }
    }

  }

  .diet-day {
    margin: 1% 0;


    .day-button {
      font-size: 14px;
      font-weight: 600;
    }

    .desayuno {
      background-color: #F4F2FF80;

      .meal-title {
        color: $color-primary-1;
      }

      .meal-icon {
        background-image: url("../../../../../assets/icons/breakfast-icon.svg");
      }

      .reload-button {
        color: $color-primary-1;
      }
    }

    .almuerzo {
      background-color: #FFF2EAB2;

      .meal-title {
        color: $color-secondary-2;
      }

      .meal-icon {
        background-image: url("../../../../../assets/icons/lunch-icon.svg");
      }

      .reload-button {
        color: $color-secondary-2;
      }
    }

    .cena {
      background-color: #ECFBFF99;

      .meal-title {
        color: $color-secondary-3;
      }

      .meal-icon {
        background-image: url("../../../../../assets/icons/dinner-icon.svg");
      }

      .reload-button {
        color: $color-secondary-3;
      }
    }
  }

  .meal-cards-container {
    columns: 300px;
    margin: 1rem;

    &>div {
      margin-bottom: 1em;
      break-inside: avoid;
    }

  }