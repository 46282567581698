@import '../../styles/variables.scss';

.navbar-top {
  .navbar-links {
    font-family: Avenir Next, "Avenir Next";
    color: $color-text;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }

  .disabled-link {
    color: $color-disabled-text;
    font-weight: 500;
    pointer-events: none;
  }

  .navbar-dropdown {

    @media (max-width: 1199.98px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
    }

    &>a {
      color: $color-text;
      font-weight: 500;
    }

    .font-avenir-next {
      font-family: Avenir Next, "Avenir Next" !important;
    }

    .navbar-dropdown-item {

      font-size: 0.9rem;

      &.active {
        overflow: hidden;
        background-color: #F4F3F2;
        color: $color-text;
      }

      &.disabled {
        color: $color-disabled-text;
        pointer-events: none;
      }
    }
  }

  .active-navbar-link {
    color: $color-primary-1;
    font-weight: bold;
    border-bottom: $color-primary-1 solid 2px;

    @media (max-width: 1199.98px) {
      color: $color-text;
      border-bottom: none;
      font-weight: 500;
    }

    &>a {
      color: $color-primary-1;
    }

    &>a:hover {
      color: $color-primary-1;
    }
  }
}


.bottom-bar {
  margin: 0;
  padding: 0.3rem 0 0.5rem;
  background: #6728FF;

  border-radius: 14px 14px 0 0;

  @media screen and (min-width: 992px) {
    display: none
  }

  & .nav-item {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & svg {
    width: 18px;
    height: 18px;
  }

  >.navbar-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  & .navbar-link {
    color: #E6E6E6;

    >div>div {
      padding: 1pt 1.2rem;
      margin: 0.2rem;
    }

    font-size: 0.9rem;
    font-family: Avenir Next,
    "Avenir Next";

    &.active-navbar-link {
      >div>div {
        margin: 3pt 0;
        background: rgba($color: #fff, $alpha: .1);
        border-radius: 25px;
      }

      font-family: $font-family-avenir-bold !important;
      color: white;
    }

    &.disabled {
      color: rgba($color: #fff, $alpha: .3) !important;
    }
  }

  .more-options-button {
    color: #E6E6E6;
    cursor: pointer;

    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.bottom-navbar-link {
  padding: 3pt 1.5rem;
  display: flex;
  gap: 1rem;

  flex-direction: row;

  align-items: center;
  justify-content: space-evenly;
  color: $color-text;
  font-family: Avenir;

  &.active-link {
    font-family: $font-family-avenir-bold;
    border-radius: 50px;
    background: rgba($color: $color-primary-1, $alpha: .1);
    color: $color-primary-1;
  }
}

.offcanvas-navbar {
  border-radius: 18px 18px 0 0;
}

.back-button-navbar {
  border-radius: 50%;

  width: 2rem;
  height: 2rem;

  & svg {
    stroke: $color-primary-1;
  }

  position: absolute;
  top: 50%;
  left: 0;

  transform: translate(20%, -50%);

  &:active {
    background: rgba($color: #000, $alpha: .1);
  }
}