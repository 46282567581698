
.spinner-image {
  width: 100%;
  aspect-ratio: 1;
}

.rotate {
  animation: spin 2s linear infinite;
}

.scale {
  animation: scale 3s ease-in-out infinite;
}

.spinner-container-small {
  max-width: 50px;
  max-height: 50px;
}

.spinner-container-medium {
  max-width: 170px;
  max-height: 170px;
}

.spinner-container-large {
  max-width: 60%;
  max-height: 60%;
}

@keyframes scale {
  0% {transform: scale(0.3);}
  50% {transform: scale(0.9);}
  100% {transform: scale(0.3);}
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.animated {
  animation: animated 2s linear infinite;
}

@keyframes animated {
  0% { transform: scale(0.2) rotate(3deg); }
  25% { transform: scale(0.4) rotate(90deg); }
  50% { transform: scale(0.8) rotate(180deg); }
  75% { transform: scale(0.4) rotate(270deg); }
  100% { transform: scale(0.2) rotate(360deg); }
}