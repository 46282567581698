@import './variables.scss';

html,
body {
  background-color: $color-background-1;
}

.page {
  background-color: $color-background-1;

  .page-container {
    max-width: 1080px;

    @media screen and (max-width: 1200px) {
      padding-top: 4%;
    }


    .home-card {
      border-radius: 20px;
      border: none;
      box-shadow: 0px -1px 0px 0px #DBDBDB inset;
      min-height: 2em;
      padding: 0%;
      margin: 0;
      box-shadow: 0 5px 5px rgba(#000, 0.2);

      overflow: auto;

      .home-title {
        font-family: $font-family-avenir-bold;
        font-size: 1.5rem;
      }

      .subtitle {
        font-size: 1.25rem;

        @media (max-width: 1199.98px) {
          font-size: 1.125rem;
        }
      }

      .home-text {
        font-weight: 500;
      }

      .steps-list {
        list-style: none;
        font-size: 0.875rem;
      }

      .home-section-image {
        border-radius: 20px 0px 0px 20px;

        @media (max-width: 992px) {
          border-radius: 20px 20px 0px 0px;
          width: 100%;
        }
      }

      .home-button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .home-button {
          padding: 5px 40px;
          font-weight: 700;
        }
      }

      .questionnaire-progress {
        width: 95%;
        margin-right: 10px;

        &>div {
          background-color: $color-secondary-1;
        }
      }

      .results-progress {
        width: 95%;
        margin-right: 10px;

        &>div {
          background-color: $color-primary-1;
        }
      }

      .questionnaire-progress-label {
        font-weight: 500;
      }

      .results-progress-label {
        font-weight: 500;
      }

      .input-search {
        margin: 2% 0;
      }

    }
  }

}

.test-modal-container {
  &>div {
    max-width: 640px;
  }

  &>div>div {
    border-radius: 20px;

    @media (max-width: 1199.98px) {
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1rem 1.5rem;
    }
  }
}

.test-register-modal-container {
  &>div {
    max-width: 810px;

    @media (max-width: 1199.98px) {
      width: calc(100% - 1rem);
    }
  }

  &>div>div {
    border-radius: 20px;

    @media (max-width: 1199.98px) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .input-test-container {
    width: 304px;
    margin: 0px auto;
  }
}

.test-modal-title {
  font-weight: 900;
}

.register-test-banner {
  background: linear-gradient(274.43deg, $color-secondary-1 17.48%, rgba(52, 179, 145, 0) 238.14%);
  color: white;
  font-weight: bolder;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .banner-button {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: small;
    font-weight: bolder;
  }
}