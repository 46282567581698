@import '../../../../styles/variables.scss';

.cardio-component-container {
  .grid-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .card-header {
      padding-bottom: 0;
    }

    @media screen and (min-width: $screen-breakpoint-md) {
      display: grid;
      gap: 1rem 3rem;
      grid-template-columns: repeat(2, 1fr);
      align-items: baseline;
    }

  }
}