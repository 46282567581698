@import '../../styles/variables.scss';

.modal-plan-header {
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem 1rem 0 1rem;
}

.periodicity {
    .button-periodicity {
        padding: 0.5rem 2.5rem;
        width: 10%;
        border-radius: 0.5rem;
    }

    .button-selected:hover {
        cursor: default; 
        background-color: $color-primary-1;
        border: 1px solid $color-primary-1;
    }
}

.description {
    font-family: "Avenir Next";
    text-align: center;

    .highlight {
        color: $color-success;
    }
}