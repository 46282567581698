.form-container {
  margin: auto;
  width: 304px;


  .password-icon {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    background-color: white;
    color: $color-primary-1
  }
}

.form-assistive-text {
  font-size: 0.75rem;
}

.form-label {
  font-family: $font-family-avenir-bold;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.2px;
}