@import '../../../styles/variables.scss';

.end-of-section-card {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #{$color-background-card-questionnaire};
  padding: 20px;
  height: 90px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: #{$mobile-breakpoint-1}px) {
    padding: 1px;
  }
}

.questionnaire-step-button-col-container {
  @media only screen and (max-width: #{$mobile-breakpoint-2}px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.questionnaire-step-button {
  display: flex;
  width: 153px;
  height: 40px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 9999px;
  border: 1px solid;
  border-color: var(--color-primary-purple-600, #6628FF);
  
  @media only screen and (max-width: #{$mobile-breakpoint-1}px) {
    padding-left: 2px;
    padding-right: 2px;
    width: 134px;
  }
    
  @media only screen and (max-width: #{$mobile-breakpoint-2}px) {
    padding-left: 2px;
    padding-right: 2px;
    width: 140px;
  }
}
