@import '../../styles/variables.scss';

.button-wrap-description-container {

  display: flex;
  gap: 1rem;

  .buttons-container {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row !important;

    justify-content: center;
  }

  .collapse-container {
    margin: 1rem 0;
  }
}