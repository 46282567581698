@import '../../styles/variables.scss';

.text-disabled {
    color: var(--color-neutral-grey-600, #A1A7AD);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
    padding: 4px 16px 4px 16px;
}

.text-link {
    color: var(--color-error-600, #F6285F);
    font-family: $font-family-avenir-bold;

    display: inline-block;
    border-radius: 8px;
    padding: 4px 16px 4px 16px;
    margin-right: 4px;
    cursor: pointer;

    @media (max-width: 1199.98px) {
      margin-bottom: 4px;
    }
}

.text-link:hover {
    color: $color-primary-1;
    background-color: $color-primary-purple-300;
}


.text-data {
    padding: 0 5rem;
    color: $color-primary-2;
    font-family: $font-family-avenir-bold;
}

.card-mobile {
  @media (max-width: 1199.98px) {
      max-width: 100%;
      margin: 0;
    }

    .image-container{
      max-height: 150px;
      overflow: hidden;
    
      .adn-image {
          border-radius: 20px 0px 0px 20px;
      
          @media (max-width: 1199.98px) {
            border-radius: 20px 20px 0px 0px;
            width: 100%;
          }
        }
    }
    
    .card-title {
      @media (max-width: 1199.98px) {
        text-align: center;
      }
    }
  }

  .btn-subscription {
    --bs-btn-color: white;
    --bs-btn-hover-color: white;
    background-color: $color-danger;
  }
  
  .btn-subscription:hover {
    background-color: $color-primary-1;
    border: 1px solid $color-primary-1;
  }

  .settings-modal {
    & > div > div {
      border-radius: 20px;

      padding: 1rem 3.75rem;
    }

    .modal-title {
      font-weight: 900;
      padding: 0 4rem;
    }

    .button-accept {
      width: 153px;
    }
  }