@import '../../../styles/variables.scss';

.explanation-paragraph {
  text-align: justify;
  margin-left: 2em;
  margin-right: 2em;
}

.page {
  .page-container {

    .diet-header {
      border-radius: 20px;
      border: none;
      box-shadow: 0px -1px 0px 0px #DBDBDB inset;

      .diet-title {
        font-size: 1.5rem;
      }
    }

    .summary-title {
      font-size: 1.25rem;
    }
  }
}

.information-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .information-title {
    font-size: 1.125rem;
    font-weight: bold;

    @media (max-width: 1199.98px) {
      font-size: 0.875rem;
    }
  }

  .label {
    font-size: 0.75rem;
  }
}


.error-container {
  flex-direction: column;
  align-items: center;

  .error-box {
    max-width: 500px;

    .error-text {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .error-button {
      color: $color-primary-1;
      text-decoration: none;
    }
  }
}

.meals-modal-container {
  &>div {
    max-width: 1080px;
  }

  .options-modal-header {
    padding: 10px 30px;
  }

  .meals-modal-title {
    font-weight: 900;
  }

  .option-card {
    height: 80%;
    background-color: $color-background-3;

    .option-title {
      font-weight: 900;
    }
  }

}

.loader-card {
  height: 300px;
  background-color: $color-background-3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-container {
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loader-text {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.imc-tooltip {
  color: $color-primary-1;
}

.tooltip-inner {
  background-color: $color-primary-1 !important;
  color: $color-background-1;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $color-primary-1 !important;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1% 0;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.card-header {
  background-color: #ffffff;
}



.bg-eq-list-item,
.bg-eq-list-item-2 {
  border-radius: 8px;
  font-size: 14px;
}

.bg-eq-list-item {
  min-height: 70px;
  width: 110px;
  background: $color-diet-eq-item-transparent-1;
}

.bg-eq-list-item-2 {
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 40%;
  }

  background: $color-diet-eq-item-transparent-2;
  height: 70px;
}

.diet-tab-item {
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.diet-tab-title {
  display: flex;
  justify-content: center;
}

.fes-details-button {
  font-size: small;
  font-family: Avenir;
  font-weight: bold;
  padding: 0.2rem 1rem;

  & .QuestionCircleFill {
    & svg {
      color: white;

      :hover {
        color: $color-primary-1
      }
    }
  }
}

.scroll-arrow {
  @media screen and (min-width: 768px) {
    display: none;
  }

  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  cursor: pointer;
}