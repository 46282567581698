@import '../../../../styles/variables.scss';

.allergies-component-container {
  .main-card-container {
    background-color: $color-background-1;
    border-radius: 20px;

    @media screen and (max-width: 767px) {
      .food-allergies {
        margin: 0.5rem;
      }
    }

    @media screen and (min-width: 768px) {
      border-radius: 0;
      margin: 1% 0;
      padding: 0;

      .food-allergies {
        margin: 1rem 0;
      }

      .card-body {
        margin: auto 5rem;
      }

      .accordion-container {
        @media screen and (max-width: 1079px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        @media screen and (min-width: $screen-breakpoint-xl) {
          columns: 400px 2;
          column-gap: 2rem;

          & div {
            break-inside: avoid;
          }

          & .accordion-button {
            font-size: large;
            padding: 1rem;
          }
        }
      }

      .accordion-button {
        text-align: center;
        padding: 1rem;
      }
    }


    .food-allergies {
      padding: 1rem;
      background-color: white;
      box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.3);
      border-radius: 17px;


      >.title {
        color: black;
        font-weight: bolder;
        font-size: large;
        display: flex;
        justify-content: center;
        margin: 0.5rem;
      }

      .question {
        display: flex;
        justify-content: center;

        & p {
          text-align: center;
        }
      }

      .allergies-description {
        display: flex;
        justify-content: center;
        margin: 1rem 0.5rem;

        & div {
          text-align: center;
          font-size: small;
          font-weight: bold;
        }

      }

      .buttons-container-allergies {
        margin: 1rem 0;

        display: flex;
        flex-direction: row;
        justify-content: center;

        .icon-button {
          margin: 0 1.2rem;
        }
      }


    }

    .collapse {
      transition: height 200ms ease;
    }

    .collapsing {
      transition: height 200ms ease;
    }

    .collapse .mt-4 {
      transition: opacity 200ms ease;
    }

    .collapse.show .mt-4 {
      opacity: 1;
    }

    .collapse:not(.show) .mt-4 {
      opacity: 0;
    }

    .collapse-container {
      margin: 1rem auto;

      @media screen and (min-width: $screen-breakpoint-md) {
        margin-bottom: 3rem;
        width: 70%;
      }
    }

  }

  .subcategories-cards-container {

    background-color: $color-background-1;
    border-radius: 20px;

    padding: 1rem 0;

    .sbcat-card {
      margin: 1rem 0.5rem;
    }

    @media screen and (min-width: $screen-breakpoint-xl) {
      border-radius: 0;
      margin: 1% 0;
      padding: 0;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      align-items: start;

      .sbcat-card {
        min-height: 400px;
        margin: 1rem 0;
      }
    }

    .sbcat-card {
      padding: 0 0 1rem;
      margin-bottom: 2rem;
      justify-content: center;

      border-radius: 30px;
      box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.3);

    }
  }
}