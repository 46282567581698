@import '../../styles/variables.scss';

.description {
  color: $color-tertiary-3;
  font-family: $font-family-avenir-bold;
}

.login-container {
  flex-direction: column;
  height: 100vh;
  
}
