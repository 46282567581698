@import '../../styles/variables.scss';

.tutorial-section-component {
  font-family: Avenir Next, "Avenir Next", "Avenir Next";

  .content-header {
    display: flex;

    flex-direction: row;
    align-items: center;

    font-family: Avenir Next Bold, "Avenir Next Bold", "Avenir Next";
    font-size: 20px;
    font-weight: bolder;

    margin: 1rem 0;
  }

  .image-container {
    img {
      height: 180px;
      object-fit: cover;
    }
  }

  .card-header {
    border: none;
    border-radius: 0;

    .btn {
      font-weight: bolder;
    }
  }

  .card-body {
    padding: 0 1rem;
  }

  .icon-element {

    background: $color-primary-1;
    border-radius: 50%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 40px;
    height: 40px;


    & svg {
      width: 25px;
      height: 25px;
      stroke: white;
    }
  }
}