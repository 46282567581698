.pre-login-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pre-login-container {
    text-align: center;
    height: 449px;
    width: 390px;
    @media (max-width: 1199.98px) {
      width: 340px;
    }

    .pre-login-text {
      font-size: 2.25rem;
      font-weight: 700;
      @media (max-width: 1199.98px) {
        font-size: 1.5rem;
      }
    }
  }
}