@import '../../../../../styles/variables.scss';

.fes {

  padding: 0 auto;

  & .accordion-header,
  & .accordion-button {
    border-radius: 20px !important;
    border: none;
    background: none;
  }

  .meal-holder {
    width: fit-content;
    height: fit-content;
  }

  .fes-search-component {
    background-color: #fff;
    margin: 0 0 1rem;

    .fes-search-component-header {
      font-size: 1.02rem;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      align-items: center;

      .item1 {
        font-weight: bolder;
      }

      @media screen and (max-width: 767px) {
        padding: 4%;

        .item1 {
          grid-row: 1;
          grid-column: 1 / 4;
        }

        .item2 {
          grid-row: 1;
          grid-column: 4;
          width: 100%;
        }

        .item3 {
          grid-row: 2;
          grid-column: 1 / 5;
        }
      }

      @media screen and (min-width: 768px) {
        padding: 1% 3%;

        .item1 {
          grid-row: 1;
          grid-column: 1;
        }

        .item2 {
          grid-row: 1;
          grid-column: 4;
          width: 100%
        }

        .item3 {
          margin: 0 40%;
          grid-row: 1;
          grid-column: 2 / 4;
          width: 100%;
        }
      }
    }

    .fes-search-component-body {
      @media screen and (max-width: 767px) {
        padding: 0.3rem;
      }

      @media screen and (min-width: 768px) {
        padding: 1rem;
      }

      .selected-item {
        cursor: pointer;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
      }

      .selected-item:active {
        box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);

        & svg {
          fill: #f6f6f6;
          stroke: $color-primary-1;
        }
      }
    }


  }

  .extra {
    background: #f6f6f6;
    border-radius: 18px;
  }

}


.mynu-stars {
  color: $color-primary-1;
}

.lightgray {
  color: lightgray;
}

.dropdown-container {
  display: grid;
  justify-content: center;

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-toggle {

    & div,
    svg {
      margin: 0 1rem;
      stroke: $color-primary-1;
    }
  }

  .dropdown-toggle:hover {
    & svg {
      stroke: white;
    }
  }

  & .dropdown-menu {
    background-color: #fcfcfc;
    padding: 1.2rem;

    border: 1px solid lightgray;
    border-radius: 18px;

    & .selected {
      background-color: $color-primary-1;
      color: #fcfcfc;
    }

    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.4s ease-in,
    transform 0.4s ease;
    display: block;
    visibility: hidden;
  }

  & .dropdown-menu.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

.fes-button-row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    justify-content: space-evenly;
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }
}

.fes-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fes-portions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  margin: 0.5rem 0;

  .recipe-name {
    font-size: clamp(12px, 14px, 16px);
    font-weight: bolder;

    @media screen and (max-width: 767px) {
      margin: 0.5rem 0 0.5rem 0.5rem;
      width: 40%;
    }

    @media screen and (min-width: 768px) {
      margin: 0.5rem 1rem;
      flex-grow: 1;
    }
  }

  .portion-amount {
    font-size: clamp(10px, 12px, 14px);

    display: flex;
    align-items: end;
    justify-content: flex-end;

    @media screen and (max-width: 767px) {
      flex-grow: 1;
      margin: 0.5rem 0.5rem 0.5rem 0;
    }

    @media screen and (min-width: 768px) {
      width: 20%;
      margin: 0.5rem 2rem;
    }
  }
}

.mynu-score-circles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 0 0.2rem;
  }

  @media screen and (min-width: 768px) {
    padding: 0 1rem;
  }

  .circles {
    border-radius: 50%;
    margin: 0 2px;
    box-shadow: inset 0 0 0 1px var(--circle-fill);
  }
}

.mynu-shadow {
  border-radius: 20px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}

.mynu-outline {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.popover-container {
  max-height: 300px;

  @media screen and (max-width: 767px) {
    min-width: 80vw;
  }

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
}

.popover-content {
  background-color: #fff;
  overflow-y: auto;
  max-height: 290px;

  @media screen and (max-width: 767px) {
    min-width: 75vw;
  }

  @media screen and (min-width: 768px) {
    min-width: 560px;
  }
}

.bg-white {
  background-color: #fff;
}

.reports {

  .reports-header {
    border-radius: 18px;
    width: 96%;
    margin: 0 auto;
    font-family: $font-family-avenir-bold;
    font-size: small;
    display: flex;

    & h4 {
      padding: 0;
      margin: 0;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;

      .header-title-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;

      .header-title-right {
        text-align: right;
      }
    }
  }

  .reports-main-container {
    display: grid;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "item1"
        "item2"
        "item3";
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "item1 item2"
        "item3 item3";

      gap: 1rem;

      .card {
        width: 100%;
      }
    }

    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "item2 item1 item3";
    }

    .item1 {
      grid-area: item1;
    }

    .item2 {
      grid-area: item2;
    }

    .item3 {
      grid-area: item3;
    }
  }

  .macro-body {
    display: grid;

    .buttons-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      width: 100%;
    }

    .macro-button,
    .selected {
      display: flex;
      margin: 0 1rem;
      padding: 0.1rem 1rem;
      border-radius: 18px;
      border: 2px solid $color-primary-1;
      color: #fff;
      cursor: pointer;
    }

    .macro-button {
      background: $color-primary-1;
    }

    .selected {
      background: #fff;
      color: $color-primary-1;
      font-weight: bolder;
    }

    .macro-body-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      &>.vl,
      .l,
      .m,
      .h,
      .vh {
        // height: 50%;
        border: 0.15rem solid;
        border-radius: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        padding: 1rem;

        &>div {
          margin-bottom: 1.5rem;
        }

      }

      &>.vl,
      .vh {
        border-color: #ff474A;
        color: #ff474A;
      }

      &>.m {
        border-color: #33b591;
        color: #33b591;
      }

      &>.l,
      .h {
        border-color: #ff8d1c;
        color: #ff8d1c;
      }

      & .color-text {
        color: $color-text;
      }
    }


  }

  .card {
    border-radius: 20px;
    border: none;
    margin: 0 auto 2%;

    @media screen and (max-width: 767px) {
      width: 90vw;
    }

    @media screen and (min-width: 768px) {
      min-height: 320px;
      width: 30vw;
    }
  }

  .card-bottom {
    @media screen and (max-width: 767px) {
      width: 90vw;
    }

    @media screen and (min-width: 768px) {
      min-height: 450px;
      width: 30vw;
    }
  }


  .card-header {
    border-radius: 20px 20px 0 0;
    border: none;
    text-align: center;
    font-family: $font-family-avenir-bold;
    font-size: large;
  }

  .card-body {
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .micro-card {
    & .carousel-indicators {
      bottom: -3rem;
    }

    .card-body {
      padding: 0;
    }

    .micro-text-recommendation {
      margin: 3rem 2rem 2rem;
    }

    .micro-chart-header {
      display: grid;
      justify-content: center;
      grid-template-rows: repeat(2, 1fr);

      &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        & svg {
          margin: 1%;
        }
      }

      .badge {
        font-size: 0.8rem;
      }
    }

  }

  .custom-range {
    width: 20px;

    .holder {
      height: 175px;

      border-radius: 18px;

      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;

      font-family: $font-family-avenir-bold;
      font-size: 1rem;
      position: relative;
    }

    .slider {
      position: absolute;
      width: 95%;
      border-radius: 18px;
      text-align: center;
      background-color: #33b591;
      color: white;
    }

    hr {
      border: none;
      border-top: 3px dotted black;
      z-index: 10000;
      width: 2.2rem;
      position: absolute;
      top: 40%;
      left: -40%;
    }
  }
}

.mynu-inner-shadow {
  background-color: #ffffff66;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 18px;

}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fes-daily-report-stack{
  $icon-path: '../../../../../assets/icons/reports/';

  .fiber-img-grain,
  .fiber-img-legume,
  .fiber-img-vegetable {
    width: 48px;
    height: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .fiber-img-legume {
    background-image: url($icon-path + 'legume.png');
  }
  
  .fiber-img-grain {
    background-image: url($icon-path + 'grain.png');
  }
  
  .fiber-img-vegetable {
    background-image: url($icon-path + 'vegetable.png');
  }
}

.search-filters {
  display: flex;
  flex-direction: row;

  &>div {
    display: inline-block;
  }

  & svg {
    margin: 0 1.1rem;
    text-align: center;
    font-size: 1.2rem;
  }
}

.daily-consumption-score {
  display: flex;
  flex-direction: column;

  .donut-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;

    position: relative;

    & .donut {
      width: 200px;
      height: 200px;
      margin: 0 auto;

      & svg {
        display: block;
        margin: auto;
      }

      #donut-percentage {
        stroke: $color-primary-1;
        stroke-linecap: round;
      }
    }

    & .score-text {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: end;

      font-family: $font-family-avenir-bold;
      color: $color-primary-1;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  & .recomended-improvement {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    justify-content: center;

    & .title-container {
      font-family: $font-family-avenir-bold;
      display: flex;
      justify-content: center;

      .title {
        margin: 1rem 0.4rem;
        padding: 0.4rem 2rem;
        background-color: $color-primary-1;
        color: white;
        border-radius: 25px;

      }

      & svg {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .improve {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.5rem;

      &>div {
        display: flex;
        justify-content: center;

        & div {
          padding: 0.2rem 1rem;
          border-radius: 18px;
          border: 1px solid lightgray;

          background: rgb(250, 250, 250);
          font-size: small;
        }

      }
    }
  }
}

.adaptation-header-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: $font-family-avenir-bold;

  & .div-stack {
    margin: 1rem 0.4rem;
    padding: 0.2rem 2rem;
    background-color: $color-primary-1;
    color: white;
    border-radius: 25px;

    & svg {
      width: 35px !important;
      height: 35px !important;
    }
  }
}