@import '../../styles/variables.scss';

.section-left {
  background-color: $color-primary-1;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-right {
  background-color: white;
}