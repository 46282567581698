@import "../../styles/variables.scss";

.custom-slider {
  width: 100%;
  border-radius: 25px;

  .slider-header {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .intervals {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .intervals-0 {
      color: #33b591;

      & svg {
        fill: #33b591;
        height: 15px;
      }
    }

    .intervals-1 {
      color: #3900b3;

      & svg {
        fill: #3900b3;
        height: 15px;
      }
    }

    .intervals-2 {
      color: #fe5133;

      & svg {
        fill: #fe5133;
        height: 15px;
      }
    }

  }

  .custom-slider-bg {
    height: var(--size);
    width: var(--slider-width);
    z-index: 1;
    border-radius: inherit;
    position: relative;

    background-color: white;
    background: linear-gradient(90deg, #33b591 25%, #3900b3, #fe5133 90%);
  }

  .custom-slider-fill {
    position: absolute;
    right: 0;
    height: var(--size);

    background-color: white;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.5);

    &::before {
      width: var(--size);
      height: var(--size);
      right: var(--before-right);

      position: absolute;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: var(--before-left);

      font-size: 0.6rem;
      text-align: center;
      font-family: $font-family-avenir-bold;
      content: var(--before-content);
      z-index: 100;

      border-radius: 50%;
      background: white;
      border: 3px solid var(--before-background);
    }
  }
}