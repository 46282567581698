  @import '../../../../../styles/variables.scss';

  .summary-container {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    .summary-loading {
      margin: auto;
    }
    
    .summary-desc {
      text-align: justify;
    }

    .summary-card {
      flex-grow: 1;
      margin: 0 0.5rem;

      max-width: 390px;

      border-radius: 18px;
      background: #F7FAFC;

      .title {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        font-family: $font-family-avenir-bold;
        background: #F4F2FF;

        .title-box {
          background-color: #F4F2FF;
          border-radius: 18px;
          color: #7270fe
        }
      }

      .summary-item {
        border: #7270fe;

        .item-container {
          width: 90%;
          height: 300px;

          @media screen and (max-width: 767px) {
            max-height: 400px;
          }

          overflow-y: auto;
          background: #fff;
          border-radius: 18px;
        }

        .wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .fill-text {
          padding: 2% 6%;
          min-height: 10%;
          font-size: 12px;
          min-height: 98px;
          text-align: justify;
          text-justify: inter-word;
        }

        .sub-text {
          padding: 2%;
          margin: 2%;
          font-size: 14px;
          text-align: center;
          color: $color-placeholder-text;
        }

        .prefer-text,
        .reduce-text,
        .avoid-text {
          display: inline-block;
          border-radius: 25px;
          font-size: 12px;
          padding: 2%;
          margin: 2%;
          color: white;
        }

        .prefer-text {
          background: $color-diet-pref-text;
        }

        .reduce-text {
          background: $color-diet-red-text;
        }

        .avoid-text {
          background: $color-diet-avoid-text;
        }

      }

      .prefer {
        background: linear-gradient(to left, $color-diet-pref-1 25%, $color-diet-pref-2 75%);
      }

      .avoid {
        background: linear-gradient(to left, $color-diet-avoid-1 2%, $color-diet-avoid-2 98%);
      }

      .reduce {
        background: linear-gradient(to left, $color-diet-red-1 2%, $color-diet-red-2 98%);
      }
    }
  }