.ugrs-bar-container {
  margin: 1rem 0;

  height: 3rem;
  width: 100%;

  .ugrs-background {
    position: relative;
    border-radius: 25px;
    height: inherit;
    background: white;
    box-shadow: inset 0 3px 3px rgba($color: #000000, $alpha: .3);

    .v-line {
      height: 4rem;
      width: 1px;
      margin: 0 0 0 0.5px;

      background: black;

      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -10%);
      z-index: 100;
    }

    .ugrs-fill {
      box-shadow: inset 0 3px 3px rgba($color: #000000, $alpha: .3);
      background-color: var(--ugrs-fill-color);
      width: var(--ugrs-fill-width);
      height: 100%;
      z-index: 10;

      position: absolute;
      top: 0;
      left: 50%;
      transform-origin: left;
      z-index: 1000;

      transition: width 0.5s ease, background-color 0.5s ease;
    }

    & svg {
      width: 25px;
      height: 25px;
    }

  }
}