@import '../../../../styles/variables.scss';

$bg-image-src: "../../../../assets/results/vitamins/images/vitamins.webp";

.vitamins-component-container {

  .micro-nutrient-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 1rem;
    margin-top: 1rem;

    .subcategories-cards-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .cards-container {
      background-color: $color-background-1;
      border-radius: 30px;

      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sbcat-card {
          margin: 0.5rem 0.5rem;
        }
      }

      @media screen and (min-width: $screen-breakpoint-lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        gap: 1rem 3rem;

        .sbcat-card {
          margin: 1rem 0 0;
        }
      }

      .sbcat-card {
        justify-content: center;

        border-radius: 30px;
        box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.3);

        .card-header {
          padding: 1rem;
          border: none;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;

          border-radius: 30px;

          >div.sbcat-title {
            font-weight: bolder;
          }

          >.sbcat-tooltip {
            background: $color-primary-1;
            padding: 0 0.8rem;
            border-radius: 25px;
            font-size: small;
            box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.3);

            color: white;
          }
        }

        .card-body {
          border: none;

          .percentile-bar-container {
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 767px) {
              margin: 1rem 0 2rem;
            }

            @media screen and (min-width: 768px) {
              margin: 2rem auto;
              width: 70%;
            }
          }

          .display-body {
            text-align: center
          }

          .vitamins-buttons-array {
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;

            .btn-primary {
              background: #34B692 !important;
              color: white;
              border: 1px solid #34B692;
              box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.2);
              font-weight: bolder;
              padding: 0.5rem 1rem;

              &.first {
                border-radius: 14px 0 0 14px;
              }

              &.second {
                border-radius: 0;
              }

              &.third {
                border-radius: 0 14px 14px 0;
              }

              &.active {
                background: white !important;
                color: #34B692;
              }
            }

            .btn-primary:hover {
              font-weight: bolder;
              background: white !important;
              color: #34B692;
            }
          }
        }

        .card-footer {
          border: none;
          padding: 1rem;

          background: none;

          display: flex;
          flex-direction: column;
          justify-content: baseline;

          p {
            text-align: justify;
          }

        }
      }
    }
  }

  .bottom-card {
    background: transparent;
    border: none;
    padding: 1rem;

    .card-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
      gap: 1rem;

      & .svg-container {
        margin: 0 auto;
        background-color: white;
        box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.3);
        border-radius: 50%;
        padding: 1rem;

        >svg {
          width: 50px;
          height: 50px;
          fill: $color-primary-1;
        }
      }

      >.title {
        padding: 0.5rem 2rem;
        color: white;
        background: $color-primary-1;
        font-weight: bold;
        font-size: medium;

        border-radius: 25px;
      }

    }

    .card-body {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      font-size: small;
    }
  }

}