.backdrop {
    z-index: 1000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;

    color: #f7e7e7;

    .display-text{
        font-size: 35px;
    }
}