@import '../../styles/variables.scss';

.form-container {
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;

    .form-control{
        display: flex;
        padding: 8px 11px;
        align-items: center;
        align-self: stretch;

        border-radius: 8px;
        border: 1px solid $color-tertiary-2;
        background: var(--color-neutral-white-500, #FFF);
    }

    .form-control::placeholder {
        color: $color-tertiary-2;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.2px;
    }

    .form-control.is-valid{
        border-color: $color-success;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2348C774' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .form-control.is-invalid{
        border-color: $color-danger;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23FF3860' class='bi bi-exclamation-triangle' viewBox='0 0 16 16'%3E%3Cpath d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/%3E%3Cpath d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    
    .input-group-text {
        background-color: #ffffff; 
    }

    .password-icon{
        border-color: $color-tertiary-2;
        border-color: $color-primary-1;
    }
    .password-icon.input-group-text::-moz-focus-inner{
        border-color: $color-primary-1;
    }

    
}

