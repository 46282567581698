@import '../../styles/variables.scss';


.toggle-button-container {
  .right {
    background: $color-primary-1;
  }

  .left {
    background: #D4D7D7;
  }

  $left-point: 3px;
  $right-point: calc(var(--size) * 1px);

  $button-height: calc(var(--size) * 1px);
  $button-width: calc(var(--size) * 2px);

  $icon-size: calc(var(--size) * 1px - 5px);

  .toggle-button-component {
    cursor: pointer;
    margin: 0 auto;
    padding: 5px 2px;

    height: #{$button-height};
    width: #{$button-width};

    border-radius: 18px;

    position: relative;

    display: flex;
    align-items: center;

    // moving ball
    .icon-component {
      position: absolute;
      padding: 1rem;
      border-radius: 50%;
      height: #{$icon-size};
      width: #{$icon-size};

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
    }

    .animate-left {
      animation: moveLeft 0.5s ease forwards;
    }

    .animate-right {
      animation: moveRight 0.5s ease forwards;
    }

    @keyframes moveLeft {

      from {
        left: #{$right-point};
      }

      to {
        left: #{$left-point};
      }
    }

    @keyframes moveRight {
      from {
        left: #{$left-point};
      }

      to {
        left: #{$right-point};
      }
    }
  }
}