@import '../../styles/variables.scss';

.questionnaire-success-container {
  @media (min-width: calc($screen-breakpoint-md - 1px)) {
    width: 60%;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;

  .text-title {
    color: var(--color-dark-600, #061237);
    text-align: center;
    font-size: 32px;
    padding: 30px 10px 1px;
  }

  .success-text {
    max-width: 800px;
    text-align: center;
    text-wrap: wrap;
  }

  .success-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .continue-button {
      width: 150px;
      padding: 8px 20px;
      border-radius: 40px;
      text-align: center;
      font-family: Avenir Next;
      font-weight: 700;
      letter-spacing: 0.2px;
    }
  }
}