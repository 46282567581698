@import '../../styles/variables.scss';

.modal-header {
    border-bottom: 0;
}
.modal-body{

    .inner-title{
        font-family: Avenir Next Bold, "Avenir Next Bold", "Avenir Next";
        font-size: 1.5rem;
        font-weight: 200;
        line-height: 1.4;
        margin-bottom: 20px;
        text-align: center;
    }

    .inner-text{
        font-family: Avenir Next, "Avenir Next Bold", "Avenir Next";
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.2px;
    }

    .button-subscribe{
        margin-top: 10px;
        margin-bottom: 20px;
        width: 40%;
        height: 40px;
        border-radius: 25px;
        border: none;
        font-family: Avenir Next Bold, "Avenir Next Bold", "Avenir Next";
        font-size: 1rem;
        font-weight: 200;
    }
}