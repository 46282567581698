@import '../../styles/variables.scss';


.mynu-accordion-container {
  font-family: Avenir;
  width: 100%;

  .accordion {
    .accordion-item {
      margin: 1rem 0;
      border: none;
      background: transparent;
      background-color: transparent;
      border-radius: 25px !important;
    }

    .accordion-header {
      font-weight: bold;
      border-radius: 25px !important;
    }
    
    .accordion-body {
      margin: 0.5rem 0 1rem;
      
      border-radius: 12px;
      border: 2px solid $color-primary-1;
      background: #fff;
      
      & img {
        @media screen and (max-width: 767px) {
          margin: 0.2rem 0;
          width: 80%;
        }
        
        @media screen and (min-width: 768px) {
          margin: 1rem 0;
          width: 40%;
        }
      }
    }
    
    .accordion-button {
      text-align: center !important;
      font-weight: bold;
      padding: 0.4rem 1rem;
      border-radius: 25px !important;
      color: $color-primary-1;
      background: #fff;
      border: 2px solid $color-primary-1;

    }

    .accordion-button::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236628FF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(180deg);
    }

    .accordion-button.collapsed {
      background: $color-primary-1;
      color: #f6f6f6;
    }

    .accordion-button.collapsed::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(0);
    }


  }
}