@import '../../styles/variables.scss';

.responsive-card-container {
  font-family: Avenir;

  background: #fff;
  padding: 0.2rem;

  border-radius: 18px;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .card-title {
      margin: 1rem 0.5rem;
      font-size: medium;
      font-weight: bolder;
    }

    .content {
      margin-bottom: 1rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .info {
        width: 95%;
        margin: 0;

        .responsive-card-description {
          padding: 0 2% 0 0;

          & div {
            text-align: justify;
            font-size: medium;
          }
        }
      }
    }
  }

  .aditional-info {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 0.5rem;

    >.sub-title {
      text-align: left;
      font-size: medium;
      font-weight: bolder;
    }

    .examples {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;

      @media screen and (max-width: 767px) {
        $initial-height: 70px;
        height: $initial-height;
        transition: height 0.1s ease-in-out;

        &.expanded {
          height: var(--height-offset);
        }
      }

      & .badge {
        margin: 2px 4px;
        padding: 0.4rem 1rem;
        text-align: center;
        font-size: small;
      }
    }

    .more {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &>svg {
        fill: $color-primary-1;
        stroke-linecap: round;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}