@import '../../styles/variables.scss';

.chat-page {
  position: absolute;
  top: 56px;

  font-family: Avenir Next, "Avenir Next", "Avenir Next";
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (max-width: calc($screen-breakpoint-lg - 1px)) {
    background: white;
    height: calc(100vh - 120px);
  }

  @media screen and (min-width: $screen-breakpoint-lg) {
    height: calc(100vh - 60px);
    left: 50%;
    transform: translateX(-50%);
  }

  .suggested-messages {

    .suggestion-title {
      font-size: 1rem;
      font-weight: 700;
    }

    .suggestion-button {
      background-color: $color-background-3;
      border: none;
      border-radius: 12px;
      margin-bottom: 0.5rem;
      border: $color-background-3 1px solid;

      .button-title {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .button-description {
        font-size: 0.875rem;
      }
    }

    .suggestion-button:hover {
      border: $color-primary-1 1px solid;
      color: $color-primary-1;
      background-color: $color-primary-purple-300;
    }
  }
}

.conversation-container {
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }

  @media screen and (max-width: calc($screen-breakpoint-lg - 1px)) {
    height: calc(100vh - 240px);
  }

  @media screen and (min-width: $screen-breakpoint-lg) {
    height: calc(100vh + 150px);
  }

  .message-container {
    white-space: pre-line;

    .avatar-name {
      font-size: 0.875rem;
    }

    .message {
      display: inline-block;
      padding: 0.5rem;
    }
  }

  .user-message {
    background-color: $color-background-1;
    border-radius: 10px;

  }

  .assistant-message {
    background-color: $color-primary-purple-500;
    border-radius: 10px;
    color: white !important;
    text-align: left;
    min-height: 40px;


    .fixed-width {
      width: 30px !important;

      & span>span {
        background-color: white !important;
      }
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .writting-spinner {
    width: 0.3rem;
    height: 0.3rem;
  }
}

.input-chat-container {
  background: white;

  @media screen and (min-width: $screen-breakpoint-lg) {
    width: 100%;
  }

  .chat-input {
    background: $color-background-1;
    resize: none;
    border: none;
    border-right: none;
  }

  .chat-input::placeholder {
    color: $color-placeholder-text;
  }

  .send-button {
    background-color: $color-background-1;
    border: none;
    border-radius: 12px;
  }

  .send-button:disabled {
    background-color: $color-background-1;
  }

  .footnote {
    font-size: 0.75rem;
    color: $color-text;
  }
}

.summary-suggestion-container {

  .title {
    font-weight: 700;
  }

  .summary-suggestion-text {
    font-size: 0.75rem;
  }

  .summary-message {
    border: 1px solid #B5B5B5;
    display: inline-block;
    border-radius: 8px;
    padding: 4px 16px 4px 16px;
    margin-right: 4px;

    @media (max-width: 1199.98px) {
      margin-bottom: 4px;
    }
  }

  .summary-message:hover {
    border: $color-primary-1 1px solid;
    color: $color-primary-1;
    background-color: $color-primary-purple-300;
  }
}

.bot-message-container {
  display: inline-block;

  .rate-msg {
    display: flex;
    align-self: center;
    text-align: center;
    align-items: center;
    margin: 0.5rem auto;

    font-size: 12px;

    .like {
      color: $color-primary-1;
    }

    .dislike {
      color: $color-text;
    }
  }
}

.chat-loader-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;

  .loader-text {
    font-size: 1.5rem;
    font-weight: 700;
  }
}