@import "../../../../styles/variables.scss";

$bg-image-path: '../../../../assets/results/diets-panel/images/';

.diet-section-container {
  margin: 4rem 0 2rem;
  font-family: Avenir;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  .card {
    width: 100%;
    border-radius: 14px;

    .card-header {
      border-radius: 14px 14px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: baseline;

      .diets-section-card-title {
        font-family: $font-family-avenir-bold;
        font-size: 1.2rem;
        background: white;
        color: $color-primary-1;
        padding: 0.2rem 1rem;
        border-radius: 25px;
      }
    }

    .card-body {
      font-family: $font-family-avenir-bold;
      padding: 0.5rem 1rem;
    }
  }

  .diet-section-types {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    .diet-section-square-btn {
      color: white;
      border-radius: 14px;

      height: 160px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        min-width: 45%;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: baseline;

      padding: 1rem;
    }
  }

  .ds-btn-header {
    font-family: $font-family-avenir-bold;
    font-size: 1.35rem;
  }

  .ds-btn-body {
    width: 100%;
    font-size: small;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .diet-section-rectangle-btn {
    border-radius: 14px;
    height: 175px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;

    color: white;

    padding: 1rem;
  }

  .diet-patterns-conatiner {
    border-radius: 50px;
    background-color: #f9f9f9;
    padding: 2rem 1rem;
    border: 1px solid #979797;

    .diet-pattern-cards-container {
      background: white;
      border: 1px solid lightgray;
      border-radius: 18px;
      padding: 1rem 5%;
    }

    .hr {
      color: $color-primary-1;
    }

    .diet-patterns-card {
      margin: 1rem 0 0;
      border: none;

      .card-header {
        padding: 0;
        border: inherit;
        display: flex;

        color: $color-primary-1;
      }

      .card-body {
        border: inherit;
        padding: 1rem 0 3rem;
      }

      .labels {
        font-size: x-small;
        margin: 0.5rem;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        & svg {
          width: 1rem;
          height: 1rem;
        }
      }

      .diet-pattern-logo-tooltip {
        margin: 2rem auto 0;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .logo-tooltip-icon {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          box-shadow: 0 3px 3px rgba($color: #000000, $alpha: .3);
          border: 1px solid #d9d9d9;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      .dp-card-toggle-icon {
        margin-top: 1rem;

        border-radius: 5px;

        width: 2rem;
        height: 2rem;
      }

    }

    .diet-patterns-customize-btn {
      font-family: $font-family-avenir-bold;

      &:active {
        color: white;
      }
    }

    .diet-pattern-title {
      font-size: small;
      font-family: $font-family-avenir-bold;
    }
  }

  .diet-section-custom-bg {
    background-size: cover;
    background-position: 0 35%;
    position: relative;

    & div {
      margin-bottom: 0.5rem;
    }

    &.panel-header {
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%, rgba(0, 0, 0, 0.60) 85%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 85%),
        url($bg-image-path + 'healthy-notebook.webp');
    }

    &.weight_loss {
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%, rgba(0, 0, 0, 0.60) 85%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 85%),
        url($bg-image-path + 'waist.webp');
    }

    &.health_carbohydrate_metabolism {
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%, rgba(0, 0, 0, 0.60) 85%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 85%),
        url($bg-image-path + 'carbs.webp');
    }

    &.health_cardiovascular {
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%, rgba(0, 0, 0, 0.60) 85%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 85%),
        url($bg-image-path + 'fruits.webp');
    }

    &.supplements_cardiovascular {
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%, rgba(0, 0, 0, 0.60) 85%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 85%),
        url($bg-image-path + 'salmon.webp');
    }

    &.diet-panel-customization {
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%, rgba(0, 0, 0, 0.60) 85%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 85%),
        url($bg-image-path + 'sparse-food.webp');
    }
  }

  .grid-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .card-header {
      padding-bottom: 0;
    }

    @media screen and (min-width: $screen-breakpoint-md) {
      display: grid;
      gap: 1rem 3rem;
      grid-template-columns: repeat(2, 1fr);
      align-items: baseline;
    }

  }
}