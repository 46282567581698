@import '../../styles/variables.scss';

.recovery-password-container {
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.note {
  font-size: 0.75rem;
  color: $color-tertiary-3;
}
