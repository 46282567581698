@import '../../styles/variables.scss';

.plan-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F7FAFC;
    border-radius: 1rem;
    padding: 1rem 1rem;

    .plan-details-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        .badge {
            display: inline-flex;
            align-items: center;
            padding: 8px 15px 8px 15px;
            border-radius: 8px;
            background-color: white;
            
            .badge-text {
                font-family: Avenir Next Bold, "Avenir Next Bold", "Avenir Next";
                font-size: 1rem;
                font-weight: 500;
                color: black;
            }
        }
        
        .badge-premium{
            background-color: #FFDD57;
        } 

        .plan-details-title {
            font-family: $font-family-avenir-bold;
            font-size: 1.5rem;
            color: $color-primary-1;
            font-weight: 500;
        }

        .plan-details-pricing {
            padding: 0 3rem;

            .plan-details-pricing-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 0;
    
                    .plan-details-pricing-price {
                        text-align: center;
                        font-family: $font-family-avenir-bold;
                        font-size: 1.7rem;
                        margin: 0;
                    }
                    .plan-details-pricing-details {
                        text-align: center;
                        font-family: $font-family-avenir-bold;
                        font-size: 0.9rem;
                    }
            }
        }
    }

    .plan-details-body {
        padding: 1rem 3rem;
        min-height: 250px;
        margin-bottom: auto;
        flex-grow: 1;

        .plan-details-body-list {
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 0.8rem;
                    .icon {
                        padding-right: 0.5rem;
                    }                    
                }
            }
        }
    }

    .plan-details-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 3rem;
        margin-top: auto;
        width: 100%;

        .plan-details-body-button-button {
            font-family: $font-family-avenir-bold;
            padding: 0.5rem;
            border-radius: 1.5rem;
            width: 100%;
        }
    }

}

.highlighted-plan {
    background-color: #FBFAFF;
    border: 1px solid $color-primary-1;
}
