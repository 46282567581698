@import '../../styles/variables.scss';


.stoplight-container {
  width: 100%;
  margin: 1rem auto;
  padding: 0.3rem 1rem;

  border-radius: 25px;
  border: 0.1rem solid lightgray;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 2rem;

  .stoplight-item {
    width: 40px;
    height: 40px;
    position: relative;

    border-radius: 50%;

    display: flex;
    border-radius: 50%;

    border: 1px solid;
    border-color: var(--fill-content);

    cursor: pointer;

    & div {
      margin: auto;
    }

    & svg {
      width: 25px;
      height: 25px;
      fill: var(--fill-content);
    }
  }

  .stoplight-item:active {
    box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.2);
  }

  .stoplight-item.active {
    background: var(--fill-content);
  }

  .stoplight-item::after {
    font-size: small;
    content: var(--after-content);
    color: var(--fill-content);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 150%);
  }

  .active {
    box-shadow: inset 0 0 0 3px var(--fill-content);

    & svg {
      fill: #fff;
    }
  }


}

.tooltip-description {
  margin: 1rem 0;
  padding: 0.5rem;
  max-width: 250px;

  background-color: #fff;

  border-radius: 18px;
  border: 0.2rem solid;

}