@import '../../../../styles/variables.scss';


.carbs-component-container {
  font-family: Avenir ;

  .card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $screen-breakpoint-lg) {
      margin: 0 2rem;
    }

    .info-section {
      margin-top: 1rem;

      gap: 1rem;
      display: flex;
      flex-direction: column;

      .text-area {
        font-weight: normal !important;
      }

      @media screen and (min-width: $screen-breakpoint-lg) {
        gap: 4rem;
        flex-direction: row;
      }
    }
  }

  .card-body {
    .buttons-accordion {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $screen-breakpoint-lg) {
        padding: 1rem;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        gap: 3rem;

        border-radius: 14px;
        border: 2px solid lightgray;

        .buttons {
          width: 100% !important;
        }
      }
    }

    & .accordion-button {
      font-size: large;
      padding: 1rem;
    }

    & .accordion-collapse,
    & .accordion-body {
      text-align: left;
      border: none !important;
    }
  }

  .card-footer {
    @media screen and (min-width: $screen-breakpoint-lg) {
      >div {
        display: flex;
        gap: 1rem;
        flex-direction: column;

        border-radius: 14px;
        border: 2px solid lightgray;
        padding: 0 1rem;
      }
    }
  }
}