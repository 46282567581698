@import '../../styles/variables.scss';


.main-text {
    color: white;
    font-size: 56px;

    @media screen and (max-width: 930px) {
        font-size: 26px;

    }

    font-weight: bold;
    font-family: $font-family-avenir-bold;
}

.sub-text {
    color: $color-landing-yellow;
    font-size: 36px;

    @media screen and (max-width: 930px) {
        font-size: 18px;
    }

    font-weight: bold;
    font-family: $font-family-avenir-bold;
}

.subsub-text {
    font-size: 24px;

    @media screen and (max-width: 930px) {
        font-size: 16px;
    }
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: auto;

    @media screen and (max-width: 930px) {
        flex-direction: column;
    }
}

.mobile-reverse {
    display: flex;

    @media screen and (max-width: 930px) {
        flex-direction: column-reverse;
    }
}

.icon {
    width: 180px;
    margin: 2%;
    font-size: 50px;

    @media screen and (max-width: 930px) {
        width: 100px;
        font-size: 30px;
    }
}

.flex-c {
    display: flex;
    flex-direction: column;
    margin: 4%;
}

.flex-r {
    display: flex;
    flex-direction: row;
    margin: 4%;
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.width-responsive {
    width: 50%;
    height: 100%;

    @media screen and (max-width: 930px) {
        width: 100%;
    }
}

.height-responsive {
    height: 250px;

    @media screen and (max-width: 930px) {
        height: 100%;
    }
}

.capitalized {
    text-transform: uppercase;
}

.spaced-1 {
    letter-spacing: 10px;
}

.spaced-2 {
    letter-spacing: 4px;
}

.spaced-3 {
    letter-spacing: 2px;
}

.point {
    cursor: pointer;
}

.color-landing {
    color: $color-landing-yellow;
}

.navbar-landing {
    height: 104px;
    padding: 0 72px;

    .navbar-nav {
        height: 104px;
    }
}

.smaller {
    height: 66px;
    padding: 0 72px;

    .navbar-nav {
        height: 66px;
    }
}

.navbar-landing,
.smaller {
    @media screen and (max-width: 930px) {
        height: 94px;
        padding: 0vh 5vw;
    }

    .collapse {
        background-color: transparent;

        @media screen and (min-width: 1200px) {
            display: flex;
            justify-content: flex-end;
        }
    }

    .collapsing,
    .show {
        background: $color-text;
    }

    .links-container {
        @media screen and (min-width: 1200px) {
            display: flex;
            flex-direction: row;
            background-color: transparent;
            margin: 0 1vw 0 2vw;
        }

        @media screen and (max-width: 930px) {
            align-items: flex-start;
            height: inherit;
            margin: 5vh 10vw;
        }

        .nav-link {
            display: flex;
            justify-content: center;
            color: $color-landing-yellow;
            font-size: medium;
            align-items: center;
            height: inherit;
            height: 100%;

            .nav-land-btn {
                color: $color-landing-yellow;
                font-family: $font-family-avenir-bold;
                border-radius: 100px;
                min-width: 200px;
                padding: 1.2vh 2vw;
                border: 1px solid $color-landing-yellow;

            }

            white-space: nowrap;

            .nav-link.show {
                color: $color-landing-features-bg2;
            }
        }

    }

    .navbar-dropdown {
        display: flex;
        background: transparent;

        .dropdown-toggle.show {
            background: transparent;
        }

        @media (max-width: 1199.98px) {
            flex-direction: column;
            justify-content: center;
            align-items: baseline;
        }

        .dropdown-menu {
            border-radius: 0;

            .dropdown-item {
                font-size: medium;
                color: $color-landing-yellow;

            }
        }
    }

    .logo {
        height: 50px;
        background-size: cover;
    }

    .logo-small {
        height: 30px;

        @media screen and (max-width: 930px) {
            height: 40px;
        }

        background-size: cover;
    }
}

.landing-page {
    overflow: hidden;

    .landing-text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12vh 0 0;
    }

    .landing-container {
        background-image: url('../../assets/images/mynu-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top -18vh left 0vw;
        width: 100%;
        height: 70vh;

        @media screen and (max-width: 930px) {
            padding: 0 10vw 0;
        }

        display: flex;
        flex-direction: column;
        min-height: fit-content;


    }

    .main-button-container {
        display: flex;
        justify-content: center;
        margin: 2%;

        @media screen and (max-width: 930px) {
            margin-top: 10%;
        }
    }

    .landing-button {
        color: #FFFFFF;
        font-family: $font-family-avenir-bold;
        border-radius: 100px;
        margin-top: 10%;
        padding: 1vh 4%;
        border: 1px solid $color-landing-yellow;
        background: $color-landing-yellow;

        min-width: 200px;
    }

    .landing-button:hover {
        background-color: $color-primary-1;
        border: 1px solid var(--is-danger-red, $color-primary-1);
    }

}

.video-landing {
    @media screen and (max-width: 930px) {
        margin: -15vh 5vw 0;
    }
}

.video-component {
    padding: 5% 0% 0%;
    padding-top: 0%;
    width: 100%;

    .wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        /* 16:9 */
    }

    .wrapper iframe {
        display: flex;
        position: absolute;
        margin: 1% 15% 0% 15%;
        width: 70%;
        height: 70%;

        @media screen and (max-width: 930px) {
            width: 90%;
            height: 90%;
            margin: 5% 5% 0% 5%;
        }
    }

    .video-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .video-header {
        color: $color-primary-1;
        font-family: $font-family-avenir-bold;
    }

    .video-subheader {
        color: $color-primary-purple-500;
        font-family: $font-family-avenir-bold;
    }

    .video-subtext-container {
        margin-top: -12%;

        @media screen and (max-width: 930px) {
            margin: 5% 0% 5%;
        }

        .video-bottom-text {
            color: $color-landing-video-bottom-text;
            font-family: $font-family-avenir-bold;
        }
    }

}

.features {
    width: 100%;

    .feature-title {
        color: white;
        font-family: $font-family-avenir-bold;
        text-transform: uppercase;
    }

    .bg-p1 {
        background-color: $color-landing-features-bg1;
    }

    .bg-p2 {
        background-color: $color-landing-features-bg2;
    }

    .bg-p3 {
        background-color: $color-landing-features-bg3;
    }

    .pill-container {
        margin: 3%;

        .pill {
            background-color: $color-landing-features-bg2;
            color: white;
            border-radius: 24px;
            padding: 0.5% 2%;
            font-size: 20px;
            font-family: $font-family-avenir-bold;
            box-sizing: border-box;

            @media screen and (max-width: 980px) {
                font-size: 16px;
            }
        }
    }

}

.call-to-action {
    margin: 0;

    .cta-title {
        color: $color-text;
        font-family: $font-family-avenir-bold;
        text-align: center;
    }

    .cta-old-price {
        color: $color-landing-video-bottom-text;
        font-family: $font-family-avenir-bold;
        text-decoration: line-through;
    }

    .cta-new-price-container {
        background: $color-landing-features-bg3;
        padding: 2%;
        border-radius: 25px;
        min-width: 300px;
    }

    .cta-new-price {
        color: white;
        font-family: $font-family-avenir-bold;
    }

    .cta-new-price-subtitle {
        color: $color-landing-features-bg3;
        margin-top: 0;
    }
}

.info-delivery {
    .pill {
        background-color: $color-landing-features-bg2;
        color: white;
        border-radius: 24px;
        padding: 0.5% 2%;
        font-size: 20px;
        font-family: $font-family-avenir-bold;
        box-sizing: border-box;

        @media screen and (max-width: 980px) {
            font-size: 16px;
        }
    }

    .web-img-container {
        @media screen and (max-width: 768px) {
            display: none;

        }
    }

    .mobile-img-container {
        @media screen and (min-width: 769px) {
            display: none;
        }
    }

}

.info-lab {
    font-size: 32px;

    @media screen and (max-width: 980px) {
        font-size: 22px;
    }

    .pill {
        background-color: $color-landing-info-lab-1;
        color: white;
        border-radius: 24px;
        padding: 0.5% 2%;
        font-size: 20px;

        @media screen and (max-width: 980px) {
            font-size: 14px;
        }

        font-family: $font-family-avenir-bold;
    }

    .margin-negative {
        margin-top: -1%;
    }

    .cards {
        height: 450px;

        @media screen and (max-width: 980px) {
            height: 100%;
        }
    }

    .bg-p1 {
        background-image: url('../../assets/images/info-lab-1.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px $color-landing-features-bg3-transparent;
    }

    .bg-p2 {
        background-image: url('../../assets/images/info-lab-2.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px $color-landing-features-bg2-transparent;
    }

    .info-lab-title {
        color: white;
        font-family: $font-family-avenir-bold;
        text-transform: uppercase;
    }

    .info-lab-subtitle {
        color: $color-landing-video-bottom-text;
        font-family: $font-family-avenir;
        margin: 2% 0% 1%;
    }

    .info-lab-text {
        color: white;
        font-family: $font-family-avenir;

        .text-content {
            margin: 2%;
        }

    }
}

.bicolor-bg {
    @media screen and (min-width: 980px) {
        background: linear-gradient(white 50%, $color-landing-features-bg3 50%);
        width: 120%;
        height: 650px;
        margin-left: -10%;
    }
}

.info-software {
    .pill {
        background-color: $color-landing-yellow;
        color: white;
        padding: 0.5% 2%;
        border-radius: 24px;
        font-size: 20px;
        align-self: flex-start;

        @media screen and (max-width: 980px) {
            font-size: 16px;
        }

        font-family: $font-family-avenir-bold;
    }

    .capture-container {
        padding: 2% 10%;

        @media screen and (min-width: 980px) {
            color: white;
        }

        .phone-capture {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0% 2% 2%;

            .phone-image {
                height: 500px;
                width: 250px;
                object-fit: cover;

                @media screen and (max-width: 980px) {
                    height: 200px;
                }
            }

            .phone-capture-text {
                margin-top: 2%;
                min-height: 65px;
                max-width: 280px;
                text-align: center;
            }
        }

    }

}

.info-categories {
    width: 100%;
    padding: 0% 0% 2%;
    color: $color-text;

    .cat-icon {
        color: #34B391;
    }

    .nav-link,
    .active {
        color: $color-text;
        height: fit-content;
    }

    .card-holder {
        max-width: 1400px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .card-item {
        height: 80px;
        min-width: 410px;

        @media screen and (max-width: 930px) {
            min-width: 100%;
        }
    }

    .hidden-mobile {
        @media screen and (max-width: 1020px) {
            display: none;
        }

        .icon {
            width: 200px;

            @media screen and (max-width: 930px) {
                width: 30px;
            }
        }
    }

    .mobile-accordion {
        @media screen and (min-width: 1020px) {
            display: none;
        }

        width: 340px;
    }

    .category-title {
        font-size: 24px;
        font-family: $font-family-avenir-bold;
        max-width: 200px;
        text-align: center;
        height: 72px;
    }

    .text-container {
        padding: 2% 5%;

        .info-text {
            color: $color-landing-video-bottom-text;
            font-family: $font-family-avenir-bold;
        }
    }
}

.stick {
    position: fixed;
    bottom: 5%;

    @media screen and (max-width: 930px) {
        bottom: 2%;
    }

    right: 2%;
}

.floating-button {
    z-index: 1000;
    bottom: 10%;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

    background: $color-primary-purple-300;
    border-radius: 100px;

    width: 70px;
    height: 70px;

    @media screen and (max-width: 930px) {
        width: 50px;
        height: 50px;
    }

    .floating-icon {
        width: 40px;
        height: 40px;

        @media screen and (max-width: 930px) {
            width: 30px;
            height: 30px;
        }
    }

}

.footer {
    background-color: black;

    height: 100%;
    width: 100%;

    .link {
        color: $color-landing-yellow;
        font-family: $font-family-avenir-bold;
    }

    .h-line {
        background-color: rgba(155, 155, 155, 0.514);
        width: 100%;
        height: 1px;
        border-radius: 1px;
        margin: 2%;
    }

    .footer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2%;
    }

    .footer-button {
        margin: 5%;
        font-family: $font-family-avenir-bold;
    }

    .button-warning {
        border: 1px solid $color-landing-yellow;
    }

    .footer-icon {
        width: 30px;
        height: 30px;
        color: $color-primary-purple-300;
    }

    .footer-link {
        color: $color-primary-purple-300;
        font-size: 20px;
    }

    .footer-text {
        color: $color-primary-purple-300;
        font-size: 16px;
    }

    .small {
        font-size: 14px;
    }

    .extra-small {
        font-size: 12px;
    }

    .bottom-text {
        margin-top: 0;
    }
}