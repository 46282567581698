@import '../../styles/variables.scss';

.banner-alert{
    display: flex;
    padding: 0.625rem 1.25rem;
    width: 90%;
    margin: 3% 5%;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 0.5rem;
    background: var(--is-warning-yellow, #FFDD57);

    .text{
        color: var(--color-neutral-grafito-500, #302E2E);
        font-family: Avenir Next;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
        letter-spacing: 0.0125rem;
    }

    .button{
        width: 1.5rem;
        height: 1.5rem;
    }
}

.banner-expired-alert{
    display: flex;
    padding: 0.625rem 1.25rem;
    width: 60%;
    margin: 3% 20%;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 0.5rem;
    background: var(--is-warning-yellow, #FFDD57);

    .text{
        color: var(--color-neutral-grafito-500, #302E2E);
        font-family: Avenir Next;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
        letter-spacing: 0.0125rem;
    }
}

.banner-warning-alert{
    display: flex;
    width: 100%;
    margin: 12px 0;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: var(--color-error-200, #FDD4DF);

    .text{
        color: var(--color-neutral-grafito-600, #292122);
        font-family: Avenir Next;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
        letter-spacing: 0.0125rem;
    }
}