@import '../../../../../styles/variables.scss';

.macro-chart {
  font-family: Avenir;
  width: 85%;
  margin: 2rem auto;
  height: 2rem;
  box-shadow: inset 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 18px;

  position: relative;

  .macro-chart-grades {
    position: absolute;
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;

    .macro-chart-fill {
      height: 100%;
      z-index: 100;
      height: 2rem;
      position: absolute;

      transition: width 0.4s ease-in;
    }

    &>div {
      height: 100%;
    }

    .item1,
    .item3 {
      border-radius: 18px 0 0 18px;
      position: relative;
    }

    .item3 {
      border-radius: 0 18px 18px 0;
    }

    .item2 {
      margin: -1% 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 2.6rem;
      border-left: 0.14em dashed black;
      border-right: 0.14em dashed black;
      z-index: 1000;

      &>div {
        margin: 0 auto;
        height: 0;
        display: flex;

        svg {
          margin: 0 0 0 -6px;
          height: 4rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    
    .item1::after {
      content: var(--after-content-item1);
      width: 70px;
      color: $color-primary-1;
      z-index: 1000;
      position: absolute;
      top: 0;
      left: 100%;
      transform: translate(-50%, -180%);
    }

    .item3::before {
      content: var(--before-content-item3);
      width: 70px;
      position: absolute;
      z-index: 1000;
      color: $color-primary-1;
      top: 0;
      left: 0;
      transform: translate(0, -180%);
    }

    .macro-chart-fill::after {
      content: var(--after-content);
      white-space: nowrap;
      position: absolute;
      left: 100%;
      transform: translateX(-20%);
      top: 150%;
      width: 70px;
    }
  }
}