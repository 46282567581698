@import '../../styles/variables.scss';

.buy-kit-layout {

  .header {
    background: $color-primary-1;
    color: #FFF;
    padding: 4vh 0 2vh 0;

    @media screen and (min-width: 768px) {
      padding: 2vh 0;
    }

    .back-button-container {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      .arrow-back {
        margin: 0 2vh;
        font-size: 30px;
        cursor: pointer;
      }

    }

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 768px) {
        justify-content: flex-end;
        margin: 0 6%;
      }

      .logo {
        width: auto;
        height: 30px;
      }
    }
  }

  .thumb-img {
    width: 18vw;

    @media screen and (min-width: 768px) {
      width: 8vw;
    }

    background-repeat: no-repeat;
    background-size: cover;
    margin: 1%;
  }

  .header-text {
    font-family: $font-family-avenir-bold;
    font-size: 20px;

    @media screen and (min-width: 768px) {
      font-size: 22px;
    }
  }

  .right {
    text-align: right;
  }

  .color-purple {
    color: $color-primary-1;
  }

  .color-gray {
    color: gray;
  }

  .mynu-bold {
    font-family: $font-family-avenir-bold;
  }

  .mynu-purple {
    color: $color-primary-1;
    font-family: $font-family-avenir-bold;
  }

  .card,
  .card-header {
    @media screen and (max-width: 767px) {
      padding: 1% 2%;
      margin: 2%;
    }

    border-radius: 18px;
    border: none;
    margin: 0 2%;
    padding: 0 1%;

  }

  .accordion {
    @media screen and (max-width: 767px) {
      margin: 2%;
    }

    .accordion-item {
      border-radius: 18px;
    }
  }

  color: $color-text;
  background: $color-background-1;

  height: 100vh;

  .terms {
    font-size: 12px;
    text-align: center;
    font-family: $font-family-avenir-bold;
  }

  .btn {
    min-width: 130px;
    margin: 2%;
    font-size: 16px;
    border-radius: 25px;
  }

  .background-blur {
    @media screen and (min-width: 768px) {
      display: none;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .mobile-hidden {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .desktop-hidden {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.stepper-component {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .stepper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;

      .stepper-body {
        min-width: 40%;
      }

      .card {
        padding: 1% 2%;
      }
    }

    .stepper-items-container {

      .item {
        display: flex;
        flex-direction: column;

        .item-content {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @media screen and (max-width: 767px) {
            align-items: flex-start;
          }

          .item-element {
            margin: 1%;
            padding: 0 1%;
            font-size: 12px;

            @media screen and (min-width: 768px) {
              font-size: 20px;
            }

            .item-header-text {
              font-weight: bold;
              font-family: $font-family-avenir-bold;

              .info-icon {
                margin: 0 2%;
              }

              @media screen and (min-width: 376px) {
                text-wrap: nowrap;
              }

            }

            .item-description {
              @media screen and (min-width: 768px) {
                font-size: 18px;
              }

              padding: 0;
              margin: 0;

              ul {
                list-style-position: inside;
                padding: 0 0;
                margin: 0 0;

                li::marker {
                  color: $color-primary-1;
                  margin-right: -10px;
                }
              }
            }

            .item-price {
              font-family: $font-family-avenir-bold;
            }

          }
        }

      }

    }
  }


  .steps-desktop {
    @media screen and (max-width: 767px) {
      display: none;
    }

    margin: 1% 10%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .step-container {
      display: flex;

      .step-container-circle {
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-circle {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 2%;
          padding: 2%;
          border-radius: 50%;
          background: rgb(168, 168, 168);
          color: white;
          font-size: 16px;
          font-weight: bold;
          width: 40px;
          height: 40px;
        }

        .highlighted {
          border: 2px solid $color-primary-1;
        }

        .active {
          background: $color-primary-1;
        }

        .step-title {
          font-size: 16px;
          font-weight: bold;
          font-family: $font-family-avenir-bold;
        }

        .step-text {
          text-align: center;
        }
      }

    }

  }
}

.confirmation-step {

  .info-section,
  .address-section,
  .order-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .section-header {
      font-size: 20px;
      font-family: $font-family-avenir-bold;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 18px;
      margin: 2%;
      font-size: 16px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }


      .label {
        font-weight: bold;
        font-family: $font-family-avenir-bold;
      }

      .value {
        width: 60%;
        text-align: right;
        padding: 2px 4% 2px 0;
        min-height: 25px;
      }
    }

  }

  .order-section {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}


.bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    min-width: 30%;
    max-width: 35%;
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    z-index: 1000;
    width: 100%;
    bottom: 0;
  }

  .buttons-container {
    border-radius: 18px;
    background-color: #FFF;

    margin: 2%;
    padding: 2%;

    .btn {
      font-family: $font-family-avenir-bold;
      color: white;
    }

    .primary {
      background: $color-primary-1;
      border: 1px solid $color-primary-1;
    }

    .primary:hover {
      background: $color-primary-2;
      border: 1px solid $color-primary-2;
    }

    @media screen and (max-width: 767px) {
      margin: 2%;
    }

  }

  .shopping-cart {
    border-radius: 18px;

    @media screen and (max-width: 767px) {
      box-shadow: 0 0 10px gray;
    }

    .accordion-button {
      background: transparent;
    }

    .accordion-button::after {
      rotate: 180deg;
    }

    .shopping-cart-header-container {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      @media screen and (min-width: 768px) {
        padding: 2%;
      }

      .shopping-cart-header {
        font-size: 20px;
        font-family: $font-family-avenir-bold;
      }

      .shopping-cart-icon {
        font-size: 25px;
      }
    }

    .shopping-cart-item-container {
      margin: 4%;
      padding: 1% 0;
      font-weight: bold;

      .shopping-cart-item-img {
        background-repeat: no-repeat;
        background-size: cover;
        margin: 2%;

        @media screen and (min-width: 768px) {
          width: 5vw;
        }
      }

      .shopping-cart-item {
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: space-between;
        }

        @media screen and (min-width: 768px) {
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
        }

        .shopping-cart-item-holder {

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .shopping-cart-item-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 2%;

            .card {
              padding: 2% 0 2%;
              width: 100%;
              margin: 4% 0;
              min-height: 25px;
              text-align: center;
            }

            .shopping-cart-item-name {
              text-wrap: nowrap;
              font-size: 18px;
              font-family: $font-family-avenir-bold;
            }
          }

        }

        .shopping-cart-item-price {
          flex-direction: column;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin: -2% 2%;
          font-size: 18px;

          @media screen and (min-width: 768px) {
            margin: 2%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          .header-text {
            color: $color-text;
            text-wrap: nowrap;
          }

          .price-text {
            font-family: $font-family-avenir-bold;
            color: $color-primary-1;
          }
        }

      }
    }

    .shopping-cart-empty {
      padding: 6%;
      font-size: 16px;
      font-weight: bold;
      color: $color-tertiary-2;
    }

    .shopping-cart-secure-transaction-banner {
      color: $color-secondary-1;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .secure-transaction-thumb-img {
        width: 10vw;

        @media screen and (max-width: 767px) {
          width: 50vw;
        }

        background-repeat: no-repeat;
        background-size: cover;
        margin: 2%;
      }

      .shopping-cart-secure-transaction-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        .shopping-cart-lock-icon {
          font-size: 20px;
          margin: 0 2% 2%;
        }

      }

    }
  }
}

.hfill,
.hfill-colored {
  align-self: center;
  color: white;
  height: 5px;
  margin: 0 4%;
  border-radius: 25px;
  width: calc(100vw / 7);
}

.hfill {
  background: gray;
}

.hfill-colored {
  background: $color-primary-1;
}

.hline {
  background: rgb(189, 189, 189);
  border-radius: 18px;
  height: 1px;
  width: 70%;
  margin: 3.5% auto;
}

.vline {
  background: white;
  border-radius: 18px;
  width: 1px;
  height: 50vh;
  margin: auto 1%;
}

.space-fill {
  @media screen and (max-height: 850px) {
    height: 15rem;
    background: $color-background-1;
  }
}