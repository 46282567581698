/* Custom CSS to hide the popover arrow */
.custom-popover {
    /* Set the arrow's size to 0 */
    --bs-popover-arrow-width: 0;
    max-width: 100% !important;
    margin-top: -1;
}

/* Hide the arrow element */
.popover-arrow {
    display: none !important;
}

.list-group {
    overflow-y: auto;
    max-height: 25vh;
    border-radius: 0 0 5px 5px;
    border: 1px solid #ced4da;
    box-shadow: 0 20px 20px rgba(0,0,0,.1) ;
}