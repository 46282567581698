@import '../../styles/variables.scss';

.food-tags-recommendator-container {
  font-family: Avenir;

  display: flex;
  flex-direction: column;

  margin: 1rem 0;
  overflow-y: auto;

  .food-tags-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: baseline;
    gap: 3rem;

    overflow-y: scroll;
    overflow-x: hidden;

    @media screen and (max-width: 767px) {
      min-height: 120px;
      gap: 0.5rem;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, .5);
    }

    .ftr {
      gap: 1rem;
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;
      justify-content: center;
      align-self: baseline;
    }

    .custom-pill {
      border-radius: 25px;
      font-size: small;
      text-align: center;

      background-color: var(--ftr-element-color) !important;
      color: white !important;

      display: flex;
      justify-content: center;
      align-items: baseline;

      &.header {
        font-size: medium;
        font-weight: bold;
        padding: 0.2rem 2rem;
      }

      >div {
        margin: auto;
        width: 100%;
      }
    }

    .pills {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      flex-direction: column;

      .custom-pill:not(.header) {
        padding: 0.5rem 0;
      }

      @media screen and (min-width: $screen-breakpoint-lg) {
        display: grid;
        grid-template-columns: repeat(var(--grid-repeat), 1fr);

        .custom-pill:not(.header) {
          padding: 0.5rem 2rem;
          width: 100%;

        }
      }
    }
  }
}