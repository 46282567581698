@import '../../styles/variables.scss';

.text-pages{
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
    letter-spacing: 0.2px;
}

.questionnaire-title{
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir Next;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.questionnaire-subtitle{
    color: $color-tertiary-2;
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}

.text-primary {
    color: $color-primary-1;
    text-align: center;
}

.height {
    position: relative; 
    padding-left: calc(1.5em + 0.75rem); 
    background-image: url("../../assets/icons/height.svg");
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
  
.height::before {
    content: "Texto alineado con la imagen"; 
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 10px; 
    font-size: 14px; 
    color: #333; 
}

.weight {
    position: relative; 
    padding-left: calc(1.5em + 0.75rem); 
    background-image: url("../../assets/icons/weight.svg");
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
  
.weight::before {
    content: "Texto alineado con la imagen"; 
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 10px; 
    font-size: 14px; 
    color: #333; 
}

.category-card {
    background-color: var(--color-neutral-grafito-50, rgba(244, 243, 242, 0.50)); 
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
}

.food-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    list-style: none;
    padding: 0;
}
  
.food-list li {
    margin-bottom: 5px;
}


.h2-category{
    color: var(--color-neutral-grafito-500, #302E2E);
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: 0.2px;
}

.exit-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  
      .text-title{
        color: var(--color-dark-600, #061237);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Avenir Next;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
  
      .continue-button {
        width: 183px;
        justify-content: center;
        border-radius: 9999px;
  
        text-align: center;
        font-family: Avenir Next;
        font-weight: 700;
        letter-spacing: 0.2px;
      }
}

.InputGroup {
    display: flex;
    align-items: center;
}

.check {
    margin-right: 8px; 
}

.check-span {
    margin-right: 6px; 
}

.container-exit-modal{
    .close {
        font-size: smaller;
    }

    .header-exit-modal {
        border-bottom: none;
    }
}

.text-title-exit-modal{
  color: var(--color-neutral-grafito-500, #302E2E);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}