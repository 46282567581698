@import '../../styles/variables.scss';

.tutorial-modal {

  @media screen and (max-width: 767px) {
    padding-left: 0 !important;
  }

  & .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $color-primary-1;
    padding: 3%;

    svg {
      cursor: pointer;
    }

    .back-button {
      min-width: 7%;
    }

    .close-button {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    .return-button {
      margin-left: 3%;

      button {
        border-color: $color-landing-yellow;
        color: $color-landing-yellow;
      }
    }
  }

  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    border-radius: 3%;
    max-width: 95%;
  }

  .tutorial-card {
    font-family: Avenir;
    color: $color-text;
    border: none;

    display: flex;
    flex-direction: column;

    background: none;
    margin: 0 0 1rem;
    padding: 0 1rem;

    & .title {
      font-family: $font-family-avenir-bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: large;
      color: $color-primary-1;

      & .title1 {
        color: $color-text;
      }

      & .title2 {
        font-size: xx-large;
      }
    }

    & .highlighted-description {
      font-family: $font-family-avenir-bold;
      color: $color-landing-yellow;
    }

    & .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: small;
      border: none;
    }

    & .card-text {
      margin: 0 auto;
      max-width: 85%;
    }

    & .card-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2%;

      @media screen and (min-width: 768px) {
        padding: 1%;
      }

      @media screen and (max-width: 767px) {
        padding-bottom: 1%;
      }

      & button {
        padding: 2% 30%;
      }
    }

    & .card-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & img {
        max-width: 78%;
      }
    }

    & .card-img-extra {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & img {
        @media screen and (max-width: 767px) {
          max-width: 75%;
        }

        @media screen and (min-width: 768px) {
          max-width: 53%;
        }
      }
    }
  }
}