@import '../../styles/variables.scss';

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;

  flex-direction: row;

  font-family: $font-family-avenir-bold;
  font-size: 1.5rem;
  text-align: center;

  @media screen and (max-width: 768px) {}
}

.img-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 8px 8px 0px 0px;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px 8px 0px 0px;

  color: white;
  font-size: 1.5rem;
  font-family: $font-family-avenir-bold;
}

.subcat-header {
  position: relative;
  width: 100%;
  height: 170px;
  border-radius: 8px 8px 0px 0px;
}

.background-container {
  background: $color-background-1;
}

.card-element {
  padding: 0;
  margin: 0;

  .card-header {
    border: none;
    padding: 0;
    margin: 0;
  }

  .card-body {
    padding: 1rem;

    @media screen and (min-width: 768px) {
      padding: 2rem 4rem;
    }
  }

}

.body-description {
  max-height: 265px;
  overflow-y: auto;
}

.foods-container {
  .food-item {
    min-width: 120px;
    padding: 2%;

    .food-name {
      font-family: $font-family-avenir-bold;
      font-size: 1rem;
    }

  }

}

.info-icon {
  font-size: 20px !important;
  color: $color-primary-1;
}

.info-text {
  color: $color-text;
  font-size: 12px;
  font-family: $font-family-avenir-bold;
  font-style: italic;
  margin: 1rem;
}

.item-results-expl {
  margin: 0 0 1%;

}