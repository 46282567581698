@import '../../../styles/variables.scss';

.fes-page {
  margin-top: 4rem;

  >.page-container {

    .diet-header {
      border-radius: 20px;
      border: none;
      box-shadow: 0px -1px 0px 0px #DBDBDB inset;

      .diet-title {
        font-size: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
    }

    .summary-title {
      font-size: 1.25rem;
    }
  }
}