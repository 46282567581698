@import '../../styles/variables.scss';

.kit-test-banner {
  background: linear-gradient(90deg, #F8537F -90%, #6628FF 100%);
  color: white;
  font-family: "Avenir Next Bold";
  font-size: medium;
  font-weight: 100;

  .banner-button {
    font-family: "Avenir Next Bold";
    font-weight: bolder;
    padding: 0.5rem 1rem;
    font-size: small;
    background-color: white;
  }

  .banner-button:hover {
    background-color: #F8537F;
    color: white;
  }

  .banner-ios {
    display: flex;
    border: 1px solid white;
    border-radius: 25px;
    padding: 0.5rem 1rem;
  }
}