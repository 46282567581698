$color-primary-1: #6628FF;
$color-background-1: #F7FAFC;

.mynu-tooltip {
  color: $color-primary-1;
}

.tooltip-inner {
  background-color: $color-primary-1 !important;
  color: $color-background-1;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $color-primary-1 !important;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $color-primary-1 !important;
}