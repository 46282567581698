@import '../../../../styles/variables.scss';

.body-component-container {
  font-family: Avenir Next;

  & .card-header {
    .header-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .info-text {
        display: flex;
        gap: 1rem;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: justify;
        color: $color-primary-1;

        border: 1.5px solid $color-primary-1;
        border-radius: 18px;
        padding: 1rem;

        font-size: normal !important;
        font-weight: normal !important;
      }

      @media screen and (min-width: $screen-breakpoint-lg) {
        gap: 4rem;
        flex-direction: row;
        justify-content: center;
      }

    }

    .body-lean-predisposition {
      gap: 2rem;
      display: flex;

      flex-direction: row;
      justify-content: center;
      align-items: baseline;

      h4 {
        text-align: center;
      }

      .info-body-lean {
        font-weight: normal;
      }

      @media screen and (min-width: $screen-breakpoint-lg) {
        align-items: center;
        gap: 3rem;
        width: 65%;

        h4 {
          text-align: justify;
        }
      }
    }
  }

  & .card-body {

    .obese-chart {
      margin: 0 auto 2rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .chart-axis-label {
        border-radius: 8px;
        padding: 0 1rem;
        border: 1px solid $color-primary-1;
        color: $color-primary-1;
      }

      .chart-obese-buttons {
        margin: 2rem;

        display: flex;
        flex-wrap: wrap;
        gap: 1rem 4rem;

        justify-content: center;
      }

      .chart-obese-labels {
        display: flex;
        flex-direction: row;

        >.label-container {
          margin: 1rem auto;
          padding: 0.2rem 1rem;
          display: flex;
          flex-direction: row;

          gap: 1rem;

          border-radius: 18px;
          border: 1.5px solid $color-primary-1;
        }
      }

      .info-container {
        width: 90%;
      }
    }

    @media screen and (min-width: $screen-breakpoint-lg) {
      .obese-chart {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 3rem;
        justify-content: center;
        align-items: baseline;

        border: 1.5px solid lightgray;
        border-radius: 18px;

        .info-container {
          width: 70%;
          margin: 0 auto;
        }

        .chart-obese-buttons {
          gap: 0.5rem 5rem;
        }
      }

      .accordion-button {
        font-size: large;
        padding: 1rem;
      }
    }
  }

  & .card-footer {
    margin: 1rem 2rem;
    padding: 1rem;
    border-radius: 25px;
    border: 1px solid $color-primary-1 !important;
    position: relative;

    &::after {
      position: absolute;
      color: $color-primary-1;
      background: white;
      border-radius: 25px;
      padding: 0 2rem;
      border: 1px solid $color-primary-1 !important;
      content: 'Gen';
      top: 0;
      font-weight: 500;
      transform: translateY(-50%);
    }
  }

  & .accordion-elements {
    @media screen and (max-width: 1079px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (min-width: $screen-breakpoint-xl) {
      columns: 400px 2;
      column-gap: 2rem;

      & div {
        break-inside: avoid;
      }

      & .accordion-button {
        font-size: large;
        padding: 1rem;
      }
    }
  }

}


.body-mechanisms-chart {
  margin-bottom: 2rem;

  .buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .left-button {
      display: flex;
      justify-content: flex-end;
      z-index: 100;

      @media screen and (max-width: 539px) {
        margin: 0;
      }

      @media screen and (min-width: 540px) {
        margin: 0 2rem;
      }
    }

    .right-button {
      display: flex;
      justify-content: flex-start;
      z-index: 100;

      @media screen and (max-width: 539px) {
        margin: 0;
      }

      @media screen and (min-width: 540px) {
        margin: 0 2rem;
      }
    }

    .center-icons {
      width: 420px;
      height: 4rem;

      position: relative;
      display: flex;
      justify-content: center;

      #person {
        position: absolute;
        z-index: 1000;
        width: 4rem;
        height: 4rem;
      }
    }
  }

  .lines-row {
    display: flex;
    justify-content: center;
    padding: 0;

    @media screen and (max-width: 539px) {
      margin: -5rem auto -3rem;
      width: 70vw;
    }

    @media screen and (min-width: 540px) and (max-width: 767px) {
      margin: -4rem auto -3rem;
      width: 340px;
    }

    @media screen and (min-width: 768px) {
      margin: -2rem auto 0;
      width: 90%;
    }

    #lines {
      z-index: 1;
    }
  }

  .bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    position: relative;

    @media screen and (max-width: 539px) {
      >div {
        margin-top: 1rem;
      }
    }

    @media screen and (min-width: 540px) and (max-width: 767px) {
      >div {
        margin: 2rem auto 0;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      &>:nth-child(1) {
        padding-left: 1rem;
      }

      &>:nth-child(4) {
        padding-right: 1rem;
      }

      >div {
        margin-top: 0.5rem;

      }
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      &>:nth-child(1) {
        padding-left: 4rem;
      }

      &>:nth-child(4) {
        padding-right: 4rem;
      }

      >div {
        margin-top: 0.5rem;

      }
    }

    @media screen and (min-width: 1200px) {
      &>:nth-child(1) {
        padding-left: 1rem;
      }

      &>:nth-child(4) {
        padding-right: 1rem;
      }

      >div {
        margin-top: 0.5rem;

      }
    }
  }

}