@import '../../styles/variables.scss';

.badge-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & h1 {
        margin-bottom: 0;
    }

    & span {
        color: $color-primary-1;
        background: white !important;
        background-color: white !important;
        padding: 5px 10px;
    }

}


