@import "../../styles/variables.scss";

.collapse-icon-component {
  font-family: Avenir !important;
  text-align: justify;

  & .collapse-icon-button {
    & svg {
      cursor: pointer;
      margin: 0.5rem;
      width: var(--svg-size);
      height: var(--svg-size);
    }
  }

  & .collapse-icon-title,
  & .collapse-icon-description,
  & .collapse-icon-elements {
    margin: 0.5rem 0;
  }
}

.collapse-icon-title {
  font-size: large;
  font-weight: bold !important;
  color: $color-text !important;
}

.collapse-icon-description {
  >p {
    font-size: medium;
    font-family: Avenir !important;
    color: $color-text !important;
    font-weight: 100 !important;
  }
}