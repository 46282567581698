@import '../../../../styles/variables.scss';

.wellness-component-container {
  font-family: Avenir !important;

  .pentagon {
    clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 50% 100%, 0% 95%);
  }

  .poly {
    clip-path: polygon(0% 0%, 50% var(--top-arch-percentage), 100% 0%, 100% 95%, 50% 100%, 0% 95%);
  }

  .pentagon-complement {
    clip-path: polygon(0% 0%, 50% 5%, 100% 0%, 100% 100%, 0% 100%);

    &.open {
      clip-path: polygon(0% 0%, 50% 10%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  @media screen and (max-width: 767px) {

    .pentagon,
    .poly,
    .pentagon-complement {
      margin: 0 0.5rem;
    }
  }

  .prs-text {
    width: 90%;
    padding: 0.5rem 1rem;
    margin: 0 0 1rem;

    background: #34B692;
    color: white;
    border-radius: 14px;
    font-size: small;
    font-weight: bold;
  }

  .tips-component {
    .btn-primary {
      max-width: 150px;
      box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.2);

      &.active {
        background-color: white;
        border: 1px solid $color-primary-1;
        color: $color-primary-1;
      }

      &:hover {
        background-color: white;
        border: 1px solid $color-primary-1;
        color: $color-primary-1;
      }
    }
  }

  .grid-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .card-header {
      padding-bottom: 0;
    }

    @media screen and (min-width: $screen-breakpoint-md) {
      display: grid;
      gap: 1rem 3rem;
      grid-template-columns: repeat(2, 1fr);
      align-items: baseline;
    }

  }
}