@import '../../../../../styles/variables.scss';

.allergies-diagram-container {
  width: 100%;
  font-family: Avenir;
  margin: 0 0 2rem;

  >div {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 767px) {
      gap: 2.5rem;
    }

    @media screen and (min-width: 768px) {
      gap: 9rem;
    }

    .icon-container {
      background: $color-primary-1;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      position: relative;

      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);

      >svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 30px;
        width: 30px;
        stroke: white;
        fill: white;
      }

      .details-text {
        background-color: white;

        padding: 2rem;
        z-index: 1000;

        position: absolute;
        top: 100%;
        left: 100%;
        transform: translate(0, 0);
      }

      & .tutorial-button {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-110%, -50%);
        border-radius: 50px;
        width: 150px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          top: 0;
          left: 0;
          transform: translate(-50%, -150%);
        }
      }

      & .tutorial-tooltip-1 {
        background: $color-background-1;
        padding: 1rem;
        color: $color-text;
        border-radius: 18px;

        width: 300px;

        z-index: 10000 !important;

        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-30%, 50%);

        &::before {
          content: "";
          position: absolute;
          height: 10%;
          width: 30%;
          border-left: 2px solid $color-secondary-1;
          border-top: 2px solid $color-secondary-1;
          left: 0;
          top: 0;
          transform: translate(15%, -120%);
          z-index: 10;
        }

        @media screen and (max-width: 767px) {
          width: 210px;
          top: 0;
          left: 0;
          transform: translate(-30%, 40%);

          &::before {
            height: 10%;
            width: 30%;
            left: 0;
            top: 0;
            transform: translate(22%, -125%);
            z-index: 10;
          }
        }

      }

      & .tutorial-tooltip-2 {
        background: $color-primary-1;
        padding: 1rem;
        color: white;
        border-radius: 18px;

        width: 300px;

        z-index: 10000 !important;

        position: absolute;
        top: 0;
        left: 0;
        transform: translate(5%, -120%);

        &::after {
          content: "";
          position: absolute;
          height: 40%;
          width: 15%;
          border-right: 2px solid $color-primary-1;
          border-bottom: 2px solid $color-primary-1;
          left: 0;
          top: 0;
          transform: translate(50%, 260%);
          z-index: 10;
        }

        @media screen and (max-width: 767px) {
          width: 180px;
          top: 0;
          left: 0;
          transform: translate(50%, -100%);

          &::after {
            width: 40%;
            height: 20%;
            top: 100%;
            left: 0;
            transform: translate(-60%, -20%);
          }
        }
      }
    }

    .icon-container.selected {
      background: white;
      box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.3);

      >svg {
        stroke: $color-primary-1;
        fill: $color-primary-1;
      }
    }

    .icon-container.right {
      background: #FE5133;
    }

    .icon-container.right.selected {
      background: white;
      box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.3);

      svg {
        stroke: #FE5133;
        fill: #FE5133;

      }
    }

    .details-button {
      width: 18px;
      height: 18px;
      background: white;

      >svg {
        height: inherit;
        width: inherit;
        fill: #34B692;
      }

      color: white;
      font-size: 13px;
      font-weight: bold;

      display: flex;
      justify-content: center;

      width: 18px;
      height: 18px;
      border-radius: 50%;

      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 220%);

      z-index: 1;
      cursor: pointer;
    }

    .details-button:hover {
      background: $color-primary-1;

      >svg {
        fill: white;
      }
    }

    .left-buttons,
    .right-buttons {
      position: relative;

      padding: 0;
      display: flex;
      flex-direction: column;

      gap: 2rem;

      @media screen and (min-width: $screen-breakpoint-md) {
        gap: 3rem;
      }

      width: 120px;
    }

    .left-buttons {

      &>:nth-child(2),
      >:nth-child(3) {
        align-self: flex-start;
      }

      &>:nth-child(1),
      >:nth-child(4) {
        align-self: flex-end;
      }
    }

    .right-buttons {

      &>div:nth-child(1),
      >div:nth-child(4) {
        align-self: flex-start;
      }

      &>div:nth-child(2),
      >div:nth-child(3) {
        align-self: flex-end;
      }
    }

    .center-circles {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      font-weight: 300;

      .left-circle,
      .right-circle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: medium;
        color: white;

        p {
          margin: 0;
          font-size: 0.75rem;
        }

      }

      .left-circle {

        width: calc(140px * var(--size-multiplier));
        height: calc(140px * var(--size-multiplier));

        border-radius: 50%;

        @media screen and (min-width: $screen-breakpoint-md) {
          width: calc(200px * var(--size-multiplier));
          height: calc(200px * var(--size-multiplier));

          p {
            font-size: 1rem;
          }
        }

        background-color: #6728FFCF;

        position: absolute;
        transform: translate(calc(-40% + 1% * var(--size-multiplier)), -50%);

        padding: 0 3rem 0 0;

      }

      img {
        position: absolute;
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
        transform: translate(0, -50%);
        z-index: 1;
      }

      .right-circle {
        width: 140px;
        height: 140px;

        border-radius: 50%;

        @media screen and (min-width: $screen-breakpoint-md) {
          width: 210px;
          height: 210px;

          p {
            font-size: 1rem;
          }
        }


        background-color: #F4AEA2;

        position: absolute;
        transform: translate(30%, -50%);

        padding: 0 0 0 4rem;

      }
    }

  }
}

.tooltip-allergies {
  opacity: 1 !important;
  font-size: medium !important;

  .tooltip-arrow::before {
    display: none;
  }

  .tooltip-inner {
    background: #34B692 !important;
  }
}