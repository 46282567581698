@import '../../styles/variables.scss';

.welcome-section {
  margin-bottom: 1rem;

  .card {
    border: none;
    padding: 0;
    box-shadow: 0 5px 5px rgba(#000000, 0.2);
    border-radius: 18px;
  }

  .card-header {
    background-image:
      linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%,
        rgba(0, 0, 0, 0.3) 85%), url('../../assets/images/home_banner.webp');
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    border-radius: 18px;
    color: white;
    font-family: Avenir;

    & div,
    p {
      z-index: 100;
    }

    & img {
      z-index: 100;
      flex-shrink: 0;
      margin: 1rem;

      @media screen and (max-width: 767px) {
        width: 107px;
        height: 23px;
      }

      @media screen and (min-width: 768px) {
        width: 185px;
        height: 36px;
      }
    }
  }


  .card-body {
    margin-top: -2rem;
    padding-top: 3rem;
    border-radius: 0 0 25px 25px;
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 8px;

  .badge-icon {
    margin-left: 8px;
  }

  .badge-text {
    font-family: Avenir Next Bold, "Avenir Next Bold", "Avenir Next";
    font-size: 16px;
    font-weight: 200;
  }

  .badge-text-premium {
    font-weight: 500;
    color: white;
  }
}

.banner-premium {
  width: 21rem;
  height: 10rem;
  object-fit: cover;
  object-position: 100% 100%;
}

.banner-nutrition {
  width: 21rem;
  border-radius: 20px 0px 0px 20px;

  @media screen and (max-width: 990px) {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
  }
}

.banner-questionnaire {
  width: 21rem;
  height: 100%;
}

.banner-kit {
  width: 21rem;
  height: 100%;
  object-fit: cover;
  object-position: 100% 110%
}