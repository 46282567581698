@import '../../styles/variables.scss';

.mynu-notification-toast {  
  border-radius: 18px;

  font-family: Avenir;

  & svg {
    fill: $color-primary-1;
    width: 1.5rem;
    height: 1.5rem;
  }

  .toast-header {
    width: 100%;
    padding: 1.5rem;
    
    border-radius: 18px;
    border: none;
    
    font-family: $font-family-avenir-bold;

    display: flex;
    gap: 1rem;

    & .btn-close {
      margin-left: auto;
    }

    & p{
      padding: 0;
      margin: 0;
    }
  }

  .toast-body {
    border: none;
    font-weight: normal !important;
  }


  &.info {
    background: #F4F2FF !important;
    border: 1px solid $color-primary-1;

    & svg {
      fill: $color-primary-1;
    }
  }

  &.warning {
    background: #FBEE78 !important;
    border: 1px solid #ffc400;

    & svg {
      fill: #ffc400;
    }
  }

  &.success {
    background: #C8F6A4 !important;
    border: 1px solid #72cd2c;

    & svg {
      fill: #72cd2c;
    }
  }

  &.danger {
    background: #F6A4A4 !important;
    border: 1px solid #ff4242;

    & svg {
      fill: #ff4242;
    }
  }
}