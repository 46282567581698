@import '../../styles/variables.scss';

.section {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.alert-success{
  color: $color-background-1;
  background-color: $color-success;
  font-size: 16px;
  font-style: normal;
  line-height: 100%; 
  letter-spacing: 0.2px;
}

.form-container {
  margin: auto;
  width: 342px;

  .description {
    color: $color-text;
  }

  .note {
    font-size: 0.75rem;
    color: $color-tertiary-3;
  }
  
  .resendCodeButton {
    font-size: 0.75rem;
    font-weight: bold;
    text-decoration: none;
  }
}
