@import '../../../../styles/variables.scss';

.results-tutorial-container {
  font-family: Avenir;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  .tutorial-card {
    display: flex;
    flex-direction: var(--card-direction);

    justify-content: center;

    border: none;

    width: 100%;

    @media screen and (max-width: calc($screen-breakpoint-md - 1px)) {
      flex-direction: column;
    }

    @media screen and (min-width: calc($screen-breakpoint-md)) and (max-width: calc($screen-breakpoint-lg - 1px)) {
      flex-direction: column;
      width: 80%;
    }

    >.tutorial-card-title-description,
    >.tutorial-card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      text-align: justify;
    }

    & .number {
      font-family: $font-family-avenir-bold;
      font-size: 2rem;
    }

    & .text {
      font-size: 1.25rem;
    }

    @media screen and (max-width: calc($screen-breakpoint-lg - 1px)) {
      padding: 0 1rem;
      margin: 1rem 0;
      align-self: center;

      .tutorial-card-content {
        align-self: center;
      }

      &.tutorial-allergies {
        margin: 0;
        padding: 0;

        .tutorial-card-title-description {
          margin: 0 1rem;
        }

        .tutorial-card-content {
          margin-top: 3rem;
        }
      }
    }

    @media screen and (min-width: calc($screen-breakpoint-lg)) {
      margin: 0;
      padding: 0 2rem 2rem;

      & .number {
        width: 100%;
        font-size: 3rem;
      }

      & .text {
        font-size: xx-large;
      }

      >.tutorial-card-title-description {
        padding: 0 2rem;
        width: 50%;
      }

      >.tutorial-card-content {
        padding: 0 2rem;
        width: 50%;
      }

      &.tutorial-allergies {
        margin-top: 3rem;
        flex-grow: 1;
        gap: 1rem;
        flex-direction: row;

        >.tutorial-card-content {
          margin: 3rem 0 0;
        }
      }
    }
  }

  & .collapse-container {
    display: flex;
    gap: 1rem;

    flex-direction: column;
    align-items: center;

    margin: 1rem;
    padding: 1rem;

    border: 3px solid $color-primary-1;
    border-radius: 14px;

    .people-group {
      width: 100px;
    }

    .empty-person,
    .half-person {
      width: 50px;
    }

    @media screen and (min-width: $screen-breakpoint-lg) {
      margin: -1rem 4rem 2rem;
      padding: 2rem;

      .people-group {
        width: 50%;
      }
    }

    position: relative;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 10px solid $color-primary-1;
      width: 0;
      height: 0;
    }

    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 10px solid $color-primary-1;
      width: 0;
      height: 0;
    }

  }
}