$color-primary-1: #6628FF;
$color-primary-2: #1FB7A8;
$color-primary-purple-500: #6E6EFF;
$color-primary-purple-300: #F4F2FF;
$color-secondary-1: #34B391;
$color-secondary-2: #FD5133;
$color-secondary-3: #34B391;
$color-tertiary-1: #203442;
$color-tertiary-2: #A1A7AD;
$color-tertiary-3: #4D5D68;
$color-background-1: #edf0f1;
$color-background-2: #2C2E33;
$color-background-3: #F4F3F280;
$color-text: #302E2E;
$color-disabled-text: #A1A7AD;
$color-placeholder-text: #827F7E;
$color-danger: #FF3860;
$color-success: #48C774;
$color-background-card-questionnaire: white;
$color-gen-results-green: #77FAD7;
$color-gen-results-blue: #6E6EFF;
$color-gen-results-red: #FA7E9F;
$mobile-breakpoint-1: 450;
$mobile-breakpoint-2: 366;

$color-diet-pref-1: #C8F6A4;
$color-diet-pref-2: #48C774;
$color-diet-avoid-1: #F6A4A4;
$color-diet-avoid-2: #F15D48;
$color-diet-red-1: #FBEE78;
$color-diet-red-2: #FBB778;

$color-diet-pref-text: #48C774;
$color-diet-avoid-text: #F15D48;
$color-diet-red-text: #FBB778;

$color-landing-yellow: #FFBE0B;

$color-landing-video-bottom-text: #3800B3;

$color-landing-features-bg1: #D0CFE6;
$color-landing-features-bg2: #9AAEFF;
$color-landing-features-bg3: #6E6EFF;
$color-landing-info-lab-1: #EF7965;

$color-landing-features-bg1-transparent: #D0CFE6DF;
$color-landing-features-bg2-transparent: #9AAEFFDF;
$color-landing-features-bg3-transparent: #6E6EFFDF;

$font-family-avenir-bold: Avenir Next Bold, "Avenir Next Bold", "Avenir Next";
$font-family-avenir: Avenir Next, "Avenir Next", "Avenir Next";

$color-diet-eq-item-transparent-1: #FBEEA521;
$color-diet-eq-item-transparent-2: #9ACFFF21;

$border-radius: 18px;

// screen brakpoints
$screen-breakpoint-xs: 310px;
$screen-breakpoint-sm: 640px;
$screen-breakpoint-md: 768px;
$screen-breakpoint-lg: 1024px;
$screen-breakpoint-xl: 1280px;
$screen-breakpoint-xxl: 1536px;
$screen-breakpoint-hd: 1920px;